export class MenuCategoryModel {
  name: any
  nameEN: any
  id: any
  preferenceID: any
  menuInactive: null
  createdByUser: any
  lastUpdateByUser: any
  lastUpdateTime: any
  reference: any
  constructor({ name, nameEN, id, preferenceID, createdByUser, lastUpdateByUser, lastUpdateTime }, reference) {
    this.name = name || null
    this.nameEN = nameEN || null
    this.id = id || null
    this.preferenceID = preferenceID || null
    this.menuInactive = null

    this.createdByUser = createdByUser || null
    this.lastUpdateByUser = lastUpdateByUser || null
    this.lastUpdateTime = lastUpdateTime || null

    this.reference = reference
  }

  toMap() {
    const result = {
      name: this.name?.trim() || null,
      nameEN: this.nameEN?.trim() || null,
      id: this.id,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,

    }
    if (this.preferenceID)
      result.preferenceID = this.preferenceID

    return result
  }
}

export function mapMenuCategories(menuCategoriesDoc) {
  const mapMenuCategory = menuCategoryDoc => new MenuCategoryModel(
    menuCategoryDoc,
    menuCategoriesDoc.ref,
  )

  const byId = (item1, item2) => item1.id - item2.id

  return menuCategoriesDoc.data().menuCategories.map(mapMenuCategory).sort(byId)
}
