export class ProductModel {
  count: any
  image: any
  menuName: any
  menuNameEN: any
  menuRef: any
  price: any
  status: any
  categoryID: any
  index: any
  loading: boolean
  constructor(product, index) {
    const {
      count,
      image,
      menuName,
      menuNameEN,
      menuRef,
      price,
      status,
      categoryID,
    } = product

    this.count = count
    this.image = image
    this.menuName = menuName
    this.menuNameEN = menuNameEN
    this.menuRef = menuRef
    this.price = price
    this.status = status
    this.categoryID = categoryID

    this.index = index
    this.loading = false
  }

  toMap() {
    return {
      count: this.count,
      image: this.image,
      menuName: this.menuName,
      menuNameEN: this.menuNameEN,
      menuRef: this.menuRef,
      price: this.price,
      status: this.status,
      categoryID: this.categoryID,
    }
  }
}

export function mapProducts(product, index) {
  return new ProductModel(
    product,
    index,
  )
}
