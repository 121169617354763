import { SHOP_ID, db } from '../db'
import { mapCompany } from '~/models/CompanyModel'

function initialState() {
  return {
    company: null,
    loading: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getCompany({ commit }) {
      commit('GET_COMPANY_REQUEST')

      const onSuccess = (company) => {
        commit(
          'GET_COMPANY_SUCCESS',
          mapCompany(company),
        )
      }

      const onError = (error) => {
        commit(
          'GET_COMPANY_FAILURE',
          error,
        )
      }

      db
        .collection('companies')
        .doc(SHOP_ID)
        .get()
        .then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_COMPANY_REQUEST(_state) {
      _state.loading = true
    },
    GET_COMPANY_SUCCESS(_state, company) {
      _state.loading = false
      _state.company = company
    },
    GET_COMPANY_FAILURE(_state) {
      _state.loading = false
    },

  },
}
