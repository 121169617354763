import { NotificationInfoModel } from './NotificationInfoModel'
import { PlaceModel } from './PlaceModel'
import { SubsidyModel } from './SubsidyModel'

export class SupplierModel {
  arrivalTime: any
  sellsLimitTime: any
  code: any
  isActive: any
  company: any
  deliverID: any
  geoPoint: any
  timeTable: any
  isBuyOnline: any
  pendingOrders: any
  approvedOrders: any
  menu: any
  subsidy: SubsidyModel | null
  place: PlaceModel | null
  isPayOnline: any
  isPayCash: any
  reference: any
  deliver: any
  route: any
  routeObject: any
  notificationInfo: NotificationInfoModel | null
  isHomeDelivery: any
  deliveryRadius: number | null
  sellsLimitPrice: number | null
  createdByUser: any
  lastUpdateByUser: any
  lastUpdateTime: any
  constructor(data, reference, deliver?, routeObject?) {
    this.arrivalTime = data.arrivalTime || null
    this.sellsLimitTime = data.sellsLimitTime || null
    this.code = data.code || null
    this.isActive = data.isActive || false
    this.company = data.company || null
    this.deliverID = data.deliverID || null
    this.geoPoint = data.geoPoint || null
    this.timeTable = data.timeTable || false
    this.isBuyOnline = data.isBuyOnline || false
    this.pendingOrders = data.pendingOrders || 0
    this.approvedOrders = data.approvedOrders || 0
    this.menu = data.menu || null
    this.subsidy = data.subsidy
      ? new SubsidyModel(data.subsidy)
      : null
    this.place = data.place
      ? new PlaceModel(data.place)
      : null
    this.isPayOnline = data.isPayOnline || false
    this.isPayCash = data.isPayCash || false
    this.reference = reference
    this.deliver = deliver
    this.route = data.route || null
    this.routeObject = routeObject
    this.notificationInfo = data.notificationInfo
      ? new NotificationInfoModel(data.notificationInfo)
      : null
    this.isHomeDelivery = data?.isHomeDelivery || false
    this.deliveryRadius = data.deliveryRadius
      ? Number(data.deliveryRadius)
      : null
    this.sellsLimitPrice = data.sellsLimitPrice
      ? Number(data.sellsLimitPrice)
      : null

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
  }

  toMap() {
    return {
      arrivalTime: this.arrivalTime,
      sellsLimitTime: this.sellsLimitTime,
      code: this.code,
      isActive: this.isActive,
      company: this.company,
      deliverID: this.deliverID,
      timeTable: this.timeTable,
      geoPoint: this.geoPoint,
      isBuyOnline: this.isBuyOnline,
      notificationInfo: this.notificationInfo
        ? this.notificationInfo.toMap()
        : null,
      isPayOnline: this.isPayOnline,
      isPayCash: this.isPayCash,
      pendingOrders: this.pendingOrders,
      approvedOrders: this.approvedOrders,
      menu: this.menu,
      subsidy: this.subsidy
        ? this.subsidy.toMap()
        : null,
      place: this.place
        ? this.place.toMap()
        : null,
      route: this.route,
      isHomeDelivery: this.isHomeDelivery,
      deliveryRadius: this.deliveryRadius,
      sellsLimitPrice: this.sellsLimitPrice,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,

    }
  }
}

export function mapSuppliers(supplier) {
  return new SupplierModel(
    supplier.data(),
    supplier.ref,
  )
}
