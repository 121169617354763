<script lang="ts">
export default {
  name: 'LandingPage',

  data: () => ({
    height: null,
  }),

  beforeMount() {
    this.height = document.documentElement.clientHeight
  },

  mounted() {
    this.height = document.documentElement.clientHeight
    window.addEventListener(
      'resize',
      this.handleResize,
    )
  },

  beforeUnmount() {
    window.removeEventListener(
      'resize',
      this.handleResize,
    )
  },

  methods: {
    handleResize() {
      this.height = document.documentElement.clientHeight
    },
  },
}
</script>

<template>
  <v-main
    style="padding: 0px"
  >
    <Carousel
      :height="height"
    />

    <AboutUs
      :height="height"
    />

    <AppContent
      :height="height"
    />

    <InviteShop
      :height="height"
    />

    <ContactForm
      :height="height"
    />

    <Contact
      :height="height"
    />

    <v-footer
      color="rgba(0, 0, 0, 0.8)"
    >
      <v-col>
        <v-row
          class="mt-2"
          justify="center"
        >
          <p
            class="text-center"
          >
            {{ $t("landingPage.copyrights", {
              "date": new Date().getFullYear(),
            }) }}
          </p>
        </v-row>
      </v-col>
    </v-footer>
  </v-main>
</template>

<style scoped>
.main {
  padding: 0px;
}

a {
  text-decoration: none;
}
</style>
