export class NotificationSettings {
  notificationDays: any
  notificationOrdersRemindingDays: any
  ordersReminding: any
  ordersRemindingTime: any
  communicationMethod: any
  constructor(data) {
    this.notificationDays = data?.notificationDays || null
    this.notificationOrdersRemindingDays = data?.notificationOrdersRemindingDays || null
    this.ordersReminding = data?.ordersReminding || null
    this.ordersRemindingTime = data?.ordersRemindingTime || null
    this.communicationMethod = data?.communicationMethod || null
  }

  toMap() {
    return {
      notificationDays: this.notificationDays,
      notificationOrdersRemindingDays: this.notificationOrdersRemindingDays,
      ordersReminding: this.ordersReminding,
      ordersRemindingTime: this.ordersRemindingTime,
      communicationMethod: this.communicationMethod,
    }
  }
}

export const COMPUNICATION_METHODS = [
  'sms',
  'email',
  'mobilePush',
]
