import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import { auth } from '../db'
import LandingPage from '../views/LandingPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing-page',
    component: LandingPage,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue'),
  },

  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('../views/SignUpPage.vue'),
  },
  {
    path: '/reset-password-page',
    name: 'reset-password-page',
    component: () => import('../views/ResetPasswordPage.vue'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/MenuPage.vue'),
  },
  {
    path: '/summary',
    name: 'summary',
    component: () => import('../views/SummaryPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfilePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/OrdersPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/NotificationPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import('../views/ConfirmationPage.vue'),
  },
  {
    path: '/error-not-customer',
    name: 'error-not-customer',
    component: () => import('../views/ErrorNotCustomerPage.vue'),
  },
  {
    path: '/error-active-account',
    name: 'error-active-account',
    component: () => import('../views/ErrorActiveAccountPage.vue'),
  },
  {
    path: '/regulation',
    name: 'regulation',
    component: () => import('../views/RegulationCompanyView.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyCompanyView.vue'),
  },
  {
    path: '/info-clause',
    name: 'info-clause',
    component: () => import('../views/InfoClauseView.vue'),
  },
  {
    path: '/verify/:id',
    name: 'verify',
    component: () => import('../views/VerifyView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior: (to, _from, savedPosition) => {
    let scrollTo = 0

    if (to.hash)
      scrollTo = Number(to.hash)
    else if (savedPosition)
      scrollTo = savedPosition.y

    return goTo(scrollTo)
  },

})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some(data => data.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser)
    next('/login')
  else next()
})

export default router
