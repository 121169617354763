import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import firebase from 'firebase/compat/app'

// eslint-disable-next-line node/prefer-global/process
export const isProduction = process.env.NODE_ENV === 'production'
const SHOP_ID_STAGING = '3bauh5VCndobLMMZlLhV'
// const SHOP_ID_STAGING = 'matOdfniTnCwFxB2W3Ch'
const SHOP_ID_PRODUCTION = 'matOdfniTnCwFxB2W3Ch'
// const SHOP_ID_PRODUCTION = 'Sr6xvJaRagdPM5HGVydC'

export const SHOP_ID = isProduction
  ? SHOP_ID_PRODUCTION
  : SHOP_ID_STAGING

const firebaseConfigStaging = {
  apiKey: 'AIzaSyB4QrN11kqO3T8DSOzIslAjeNTuonjop4M',
  authDomain: 'kanapkaman-staging-4ddb9.firebaseapp.com',
  databaseURL: 'https://kanapkaman-staging-4ddb9.firebaseio.com',
  projectId: 'kanapkaman-staging-4ddb9',
  storageBucket: 'kanapkaman-staging-4ddb9.appspot.com',
  messagingSenderId: '1009118701495',
  appId: '1:1009118701495:web:1f74787188d4d8d26daa32',
  measurementId: 'G-3KHVDC9FLR',
}

const firebaseConfigProduction = {
  apiKey: 'AIzaSyBwX2Q8Cutj6ecFT0_xxOFhm9Q3CE7nD54',
  authDomain: 'kanapkaman-dd0bc.firebaseapp.com',
  databaseURL: 'https://kanapkaman-dd0bc.firebaseio.com',
  projectId: 'kanapkaman-dd0bc',
  storageBucket: 'kanapkaman-dd0bc.appspot.com',
  messagingSenderId: '603285153380',
  appId: '1:603285153380:web:ce0b0ff042c625f87ee171',
  measurementId: 'G-LCG50CGNWR',
}

export const paymentMerchantId = isProduction
  ? '9de8ce865d49108b667c92a66c571fad'
  : '31ab1a82f1669bfaefc850ca1dce6463'

export const paymentHashSalt = isProduction
  ? 'slE4%Ga8%Na1^No1'
  : 'DRi9^VO2*SLo0$CR'

const firebaseConfig = isProduction
  ? firebaseConfigProduction
  : firebaseConfigStaging

export const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const auth = firebase.auth()

const missingCompaniesCollection = db.collection('missingCompanies')
const usersCollection = db.collection('users')
const collectionMenuCategories = db.collection('menuCategories')
const collectionMenu = db.collection('menu')
const collectionSupplier = db.collection('supplier')
const collectionOrders = db.collection('orders')
const collectionUpdateApplication = db.collection('updateApplication')
const collectionDelivers = db.collection('delivers')
const collectionNotificationForUsers = db.collection('notificationForUsers')
const collectiveInvoices = db.collection('collectiveInvoices')
const reportBugOrSuggestionCollection = db.collection('reportBugOrSuggestion')

const { FieldValue, Timestamp, GeoPoint } = firebase.firestore

export {
  db,
  auth,
  firebase,

  usersCollection,
  missingCompaniesCollection,
  collectionMenuCategories,
  collectionMenu,
  collectionSupplier,
  collectionOrders,
  collectionUpdateApplication,
  collectionDelivers,
  collectionNotificationForUsers,
  collectiveInvoices,
  reportBugOrSuggestionCollection,

  FieldValue,
  Timestamp,
  GeoPoint,
}
