import { mapProducts } from './ProductModel'
import { withoutEmptyString } from '~/helpers/text'

const mapProductsToMap = product => product.toMap()

export class OrderModel {
  address: any
  customerName: any
  date: any
  orderNumber: any
  progress: any
  supplier: any
  user: any
  completementStatus: any
  isOfficeOrder: any
  deliver: any
  route: any
  deliveryTime: any
  deliveryTimeTo: any
  deliveryTimeFrom: any
  paymentStatus: any
  isPayment: any
  paymentMethod: any
  paymentId: any
  phone: any
  commentToOrder: any
  company: any
  isPublic: any
  source: any
  subsidyIdentifier: any
  supplierCity: any
  communicationMethod: any
  orderDate: any
  invoice: any
  subsidyAmount: any
  orders: any
  createdByUser: any
  lastUpdateByUser: any
  lastUpdateTime: any
  reference: any
  loading: boolean
  constructor(data, reference) {
    this.address = data.address || null
    this.customerName = data.customerName || null
    this.date = data.date || null
    this.orderNumber = data.orderNumber || null
    this.progress = data.progress || null
    this.supplier = data.supplier || null
    this.user = data.user || null
    this.completementStatus = data.completementStatus || null
    this.isOfficeOrder = data.isOfficeOrder || false
    this.deliver = data.deliver || null
    this.route = data.route || null
    this.deliveryTime = data.deliveryTime || null
    this.deliveryTimeTo = data.deliveryTimeTo || null
    this.deliveryTimeFrom = data.deliveryTimeFrom || null
    this.paymentStatus = data.paymentStatus || null
    this.isPayment = data.isPayment || false
    this.paymentMethod = data.paymentMethod || null
    this.paymentId = data.paymentId || null
    this.phone = data.phone || null
    this.commentToOrder = data.commentToOrder || null
    this.company = data.company || null
    this.isPublic = data.isPublic || false
    this.supplierCity = data.supplierCity || null
    this.source = data.source || null
    this.subsidyIdentifier = data.subsidyIdentifier || null
    this.communicationMethod = data.communicationMethod || null
    this.orderDate = data.orderDate || null
    this.invoice = data.invoice || null
    this.subsidyAmount = data.subsidyAmount || 0

    this.orders = data.orders?.map(mapProducts) || []

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null

    this.reference = reference || null
    this.loading = false
  }

  toMap() {
    return {
      address: this.address,
      customerName: withoutEmptyString(this.customerName),
      date: this.date,
      orderDate: this.orderDate,
      orderNumber: this.orderNumber,
      progress: this.progress,
      supplier: this.supplier,
      user: this.user,
      completementStatus: this.completementStatus,
      isOfficeOrder: this.isOfficeOrder,
      deliver: this.deliver,
      route: this.route,
      deliveryTime: this.deliveryTime,
      deliveryTimeTo: this.deliveryTimeTo,
      deliveryTimeFrom: this.deliveryTimeFrom,
      isPayment: this.isPayment,
      paymentMethod: this.paymentMethod,
      paymentStatus: this.paymentStatus,
      paymentId: this.paymentId,
      supplierCity: this.supplierCity,
      phone: withoutEmptyString(this.phone),
      commentToOrder: this.commentToOrder,
      company: this.company,
      isPublic: this.isPublic,
      source: this.source,
      subsidyIdentifier: this.subsidyIdentifier,
      communicationMethod: this.communicationMethod,
      invoice: this.invoice,
      subsidyAmount: this.subsidyAmount,

      orders: this.orders?.map(mapProductsToMap) || [],

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,

    }
  }
}

export const PAYMENT_METHODS = [
  'blik',
  'cardOnline',
  'cash',
  'cardOnDelivery',
  'overflowInvoice',
]

export const PAYMENT_METHODS_SUBSIDY = [
  'subsidyBlik',
  'subsidyCardOnline',
  'subsidyCash',
  'subsidyCardOnDelivery',
]

export function mapOrders(data) {
  return new OrderModel(
    data.data(),
    data.ref,
  )
}

export const Order = {
  paymentStatus: {
    success: 'success',
    waiting: 'waiting',
    failed: 'failed',
    refund: 'refund',
  },
  completementStatus: {
    waitingFirmAccept: 'waitingFirmAccept',
    waitingCustomerAccept: 'waitingCustomerAccept',
    completed: 'completed',
    cancelled: 'cancelled',
    rejected: 'rejected',
    failed: 'failed',
  },
  paymentMethod: {
    cardOnline: 'cardOnline',
    cardOnDelivery: 'cardOnDelivery',
    cash: 'cash',
    blik: 'blik',
    subsidy: 'subsidy',
    subsidyBlik: 'subsidyBlik',
    subsidyCardOnline: 'subsidyCardOnline',
    subsidyCash: 'subsidyCash',
    subsidyCardOnDelivery: 'subsidyCardOnDelivery',
  },
  status: {
    approved: 'approved',
    pending: 'pending',
    rejected: 'rejected',
  },
}
