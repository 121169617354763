export class NotificationInfoModel {
  notificationCount: number
  lastNotificationTime: any
  lastNotificationType: any
  lastNotificationMessage: any
  constructor(data) {
    this.notificationCount = Number(data.notificationCount || 0)
    this.lastNotificationTime = data.lastNotificationTime || null
    this.lastNotificationType = data.lastNotificationType || null
    this.lastNotificationMessage = data.lastNotificationMessage || null
  }

  toMap() {
    return {
      notificationCount: this.notificationCount,
      lastNotificationTime: this.lastNotificationTime,
      lastNotificationType: this.lastNotificationType,
      lastNotificationMessage: this.lastNotificationMessage,
    }
  }
}
