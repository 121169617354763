<script setup lang="ts">
import { onMounted, ref, toRefs, watch } from 'vue'

const props = defineProps({
  height: {
    type: Number,
    required: true,
  },
})

const { height } = toRefs(props)

const heightData = ref(100)

const items = [
  { src: `/images/carousel/0.png` },
  { src: `/images/carousel/1.png` },
  { src: `/images/carousel/2.png` },
]

const itemsMax = [
  { src: `/images/carousel-max/0.png` },
  { src: `/images/carousel-max/1.png` },
  { src: `/images/carousel-max/2.png` },
]

const itemsMin = [
  { src: `/images/carousel-min/0.png` },
  { src: `/images/carousel-min/1.png` },
  { src: `/images/carousel-min/2.png` },
]

watch(height, (heightNew) => {
  if (!heightData.value || !(document.documentElement.clientWidth < 600))
    heightData.value = heightNew
})

onMounted(() => {
  heightData.value = height.value
})
</script>

<template>
  <div
    id="start-page"
  >
    <v-carousel
      class="d-md-block d-none d-sm-none d-sx-none"
      hide-delimiter-background
      show-arrows
      continuous
      cycle
      :height="heightData"
      :interval="6000"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
      >
        <v-img
          :lazy-src="`${item.src}`"
          :src="itemsMax[i].src"
          :height="heightData"
        />
      </v-carousel-item>
    </v-carousel>

    <v-carousel
      class="d-lg-none d-md-none d-xl-none"
      hide-delimiter-background
      show-arrows
      cycle
      :height="heightData"
    >
      <v-carousel-item
        v-for="(item, i) in itemsMin"
        :key="i"
        :src="`${item.src}`"
      />
    </v-carousel>
  </div>
</template>

<style>
.v-btn--active span span svg {
  color: #93C714;
}

.v-input--btn i {
  pointer-events: none;
}

.v-input--btn {
  height: 24px;
  width: 24px;
}

.v-window__next,
.v-window__prev {
  margin: 0;
  border-radius: 0;
}

.v-window__next button::before,
.v-window__prev button::before,
.v-window__next button,
.v-window__prev button {
  background: rgba(0, 0, 0, 0.8) !important;
  background-color: black;
  border-radius: 0 !important;
  width: 80px !important;
  height: 50px !important;
  transform: none;
  opacity: 1;
  transition: none !important;
}

.v-window__next button span span {
  width: 20px;
}

.v-window__prev button span span {
  width: 20px;
}
</style>
