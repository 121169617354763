<script setup lang="ts">
import { mdiChevronRight } from '@mdi/js'
import { type Ref, computed, onMounted, ref } from 'vue'

const caorrectHeigh = ref(0)
const containerRef: Ref<null | { clientHeight: number }> = ref(null)

const height = computed(() => {
  return caorrectHeigh.value < document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : caorrectHeigh.value
})

onMounted(() => {
  const height1 = document.getElementById('about-us')?.getBoundingClientRect().height || 100
  const isExtraSmall = document.documentElement.clientWidth < 350
    ? height1 + 200
    : height1 + 100

  caorrectHeigh.value = document.documentElement.clientWidth < 450
    ? isExtraSmall
    : containerRef.value?.clientHeight || 0
})
</script>

<template>
  <v-img
    :height="height + 20"
    min-height="800px"
    src="/images/landing-page/aboutUs.jpg"
    lazy-src="/images/landing-page/aboutUs-lq.jpg"
  >
    <v-container
      id="about-us"
      ref="containerRef"
      class="fill-height fluid"
    >
      <v-col
        class="fill-height fluid"
      >
        <v-row
          class="fill-height fluid"
          align="center"
          justify="center"
        >
          <v-col>
            <v-row
              class="mb-5"
              align="center"
              no-gutters
            >
              <v-col
                class="d-none d-sm-flex"
                cols="1"
                sm="4"
                md="5"
                lg="5"
              >
                <v-img
                  src="/images/landing-page/line.png"
                />
              </v-col>

              <v-col
                class="text-center"
                cols="12"
                sm="4"
                md="2"
                lg="2"
              >
                <h1>{{ $t("AboutUs.title") }}</h1>
              </v-col>

              <v-col
                class="d-none d-sm-flex"
                cols="1"
                sm="4"
                md="5"
                lg="5"
              >
                <v-img
                  src="/images/landing-page/line.png"
                />
              </v-col>
            </v-row>

            <br>

            <v-container
              style="max-width: 550px;"
              class="mt-0 pt-0"
            >
              <v-col
                class="text-center"
              >
                <h3>{{ $t("AboutUs.aboutUs1") }}</h3>

                <p>{{ $t("AboutUs.aboutUs2") }}</p>

                <br>

                <h3>{{ $t("AboutUs.aboutUs3") }}</h3>

                <p>{{ $t("AboutUs.aboutUs4") }}</p>

                <br>

                <h3>{{ $t("AboutUs.aboutUs5") }}</h3>

                <p>{{ $t("AboutUs.aboutUs6") }}</p>

                <br>

                <h3>{{ $t("AboutUs.aboutUs7") }}</h3>

                <h3>{{ $t("AboutUs.aboutUs8") }}</h3>

                <br>

                <router-link
                  v-slot="{href}"
                  to="#contact"
                >
                  <v-btn
                    outlined
                    large
                    :href="href"
                    color="primary"
                  >
                    <span
                      class="font-weight-bold text-none"
                    >{{ $t("AboutUs.aboutUs9") }}</span>

                    <v-icon
                      right
                    >
                      {{ mdiChevronRight }}
                    </v-icon>
                  </v-btn>
                </router-link>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </v-img>
</template>
