import axios from 'axios'
import dayjs from 'dayjs'
import { FieldValue, collectionOrders, collectiveInvoices, db, isProduction } from '../db'
import { onUpdate } from '../helpers/actions'
import router from '../router'
import store from './index'
import { AddressModel } from '~/models/AddressModel'
import { NotificationSettings } from '~/models/NotificationSettings'
import { Order, mapOrders } from '~/models/OrderModel'
import { ProductModel } from '~/models/ProductModel'

// const baseURLProduction
// = 'https://us-central1-kanapkaman-dd0bc.cloudfunctions.net'
const baseURLProduction
  = 'https://europe-west1-kanapkaman-dd0bc.cloudfunctions.net'

const baseURLStaging
  = 'https://europe-west1-kanapkaman-dd0bc.cloudfunctions.net'// 'https://us-central1-kanapkaman-staging-4ddb9.cloudfunctions.net'

const baseURL = isProduction
  ? baseURLProduction
  : baseURLStaging

const HEADERS_FIREBASE = {
  'Access-Control-Allow-Headers':
    'Origin, X-Requested-With, Content-Type, Accept',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PATCH, PUT',
  'Access-Control-Allow-Origin': '*',
}

const HEADERS_FIREBASE2 = {
  'Content-Type': 'application/x-www-form-urlencoded',
}
const PAYMENT_METHODS_SUBSIDY = [
  'subsidyCardOnline',
  'subsidyCash',
  'subsidyCardOnDelivery',
]

function getAxiosFirebase({ reference: { id } }) {
  return axios.create({
    baseURL,
    headers: {
      ...HEADERS_FIREBASE,
      collection: 'users',
      uid: id,
    },
  })
}

function getAxiosFirebase2({ reference: { id } }) {
  return axios.create({
    baseURL,
    headers: {
      ...HEADERS_FIREBASE2,
      collection: 'users',
      uid: id,
    },
  })
}
const removeEmpty = obj => Object.keys(obj).forEach(key => obj[key] == null && delete obj[key])

const FirebaseFunctionsNames = {
  getMoneyForOrder: '/getMoneyForOrder',
  groupOrderRequestOrCancelled: 'groupOrderRequestOrCancelled',
  sentNotificationToUser: '/sentNotificationToUser',
  getCompanyDataByNIP: '/getCompanyDataByNIP',
  transactionRegisterPrzelewy24: '/transactionRegisterPrzelewy24',
  transactionVerifyPrzelewy24: '/transactionVerifyPrzelewy24',
  transactionRefundPrzelewy24: '/transactionRefundPrzelewy24',
}

function initialState() {
  return {
    orders: null,
    ordersDelivers: null,
    selectedOrder: null,
    loadingCreateOrder: false,
    error: null,
    cardData: null,

    invoiceData: null,
    invoiceLoading: false,
    invoiceError: null,

    invoiceUserData: null,
    invoiceUserLoading: false,
    invoiceUserError: null,

    verifyOrderResponse: null,
  }
}

function setLoadingProduct({ reference: { id } }, index, key, value) {
  return (orderItem) => {
    const updateProduct = (index1, key1, value1) => (product) => {
      if (product.index === index1)
        product[key1] = value1

      return product
    }

    if (orderItem.reference.id === id) {
      return {
        ...orderItem,
        orders: orderItem.orders.map(updateProduct(
          index,
          key,
          value,
        )),
      }
    }

    return orderItem
  }
}

function updateOrder({ reference: { id } }, key, value) {
  return (orderItem) => {
    if (orderItem.reference.id === id)
      orderItem[key] = value

    return orderItem
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async getInvoice({ commit, rootState: { app: { userData } } }, nip) {
      commit('GET_INVOICE_REQUEST')
      try {
        const data = await getAxiosFirebase(userData).post(FirebaseFunctionsNames.getCompanyDataByNIP, { nip })
        commit('GET_INVOICE_SUCCESS', data)
      }
      catch (error) {
        commit('GET_INVOICE_ERROR', error)
      }
    },

    async getUserInvoice({ commit }, nip) {
      commit('GET_USER_INVOICE_REQUEST')
      try {
        const data = await collectiveInvoices.where('nip', '==', nip).get()
        commit('GET_USER_INVOICE_SUCCESS', data.docs.length
          ? data.docs[0].data()
          : null)
      }
      catch (error) {
        commit('GET_USER_INVOICE_ERROR', error)
      }
    },
    async createOrder({ commit, rootState: { app: { userData }, serverTime: { serverTime } } }, { order, supplier, company, locale }) {
      const onUpdateFunc = userDataReference => ({
        lastUpdateByUser: userDataReference,
        lastUpdateTime: FieldValue.serverTimestamp(),
      })

      if (!userData.displayName || !userData.phone) {
        await userData.reference.update({
          displayName: order.customerName,
          phone: order.phone,
          ...onUpdateFunc(userData.reference),
        })
      }

      commit('CREATE_ORDER_REQUEST')
      try {
        let data = null
        let orderRef = null
        try {
          data = await collectionOrders.doc('order_number').get()
        }
        catch (error) {
          commit(
            'CREATE_ORDER_ERROR',
            error,
          )
        }

        const orderNumber = data.data().number + 1
        order.orderNumber = orderNumber
        try {
          await data.ref.update(
            'number',
            FieldValue.increment(1),
          )
        }
        catch (error) {
          commit(
            'CREATE_ORDER_ERROR',
            error,
          )
        }

        try {
          orderRef = await collectionOrders.add({
            ...order.toMap(),
            createdByUser: userData.reference,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: FieldValue.serverTimestamp(),
          })
        }
        catch (error) {
          commit(
            'CREATE_ORDER_ERROR',
            error,
          )
        }

        order.reference = orderRef
        const description = `Zamówienie Nr ${orderNumber} ${orderRef.id}`

        const onSuccess = async (isShowSuccessDialog) => {
          const callData = {
            order: JSON.stringify({
              userRef: userData.reference.id,
              sellsPointName: supplier?.place?.name,
              sellsAddress: supplier?.place?.address,
              deliverID: order.deliver.id,
              notificationType: order.completementStatus === Order.completementStatus.completed
                ? 'order_confirm'
                : 'order_request',
            }),
          }
          const orderCount = order.route.update(
            'ordersCount',
            FieldValue.increment(1),
          )
          let supplierPendingOrders = null
          let supplierApprovedOrders = null
          let routePendingOrders = null
          if (order.completementStatus === Order.completementStatus.completed) {
            supplierApprovedOrders = order.supplier.update({
              approvedOrders: FieldValue.increment(1),
            })
          }
          else {
            supplierPendingOrders = order.supplier.update({
              pendingOrders: FieldValue.increment(1),
            })
            routePendingOrders = order.route.update({
              pendingOrders: FieldValue.increment(1),
            })
          }
          store.dispatch('menu/clearCart')
          // eslint-disable-next-line no-proto, no-restricted-properties
          const formatSettings = settings => (settings.__proto__.constructor === NotificationSettings
            ? userData.notificationSettings.toMap()
            : userData.notificationSettings)

          const notificationSettings = userData?.notificationSettings
            ? formatSettings(userData?.notificationSettings)
            : {}

          notificationSettings.communicationMethod = order.communicationMethod

          let updateUserData = {
            displayName: order.customerName,
            phone: order.phone,
            notificationSettings,
          }

          if (PAYMENT_METHODS_SUBSIDY.includes(order.paymentMethod)) {
            updateUserData = {
              ...updateUserData,
              'work.subsidyIdentifier': order.subsidyIdentifier,
            }
          }

          if (!order.isOfficeOrder) {
            updateUserData = {
              ...updateUserData,
              home: new AddressModel(order.address).toCustomerHomeMap(),
            }
          }
          const forEachItems = value => store.dispatch(
            'app/updateUserData',
            {
              key: value,
              value: updateUserData[value],
            },
          )

          Object.keys(updateUserData).forEach(forEachItems)

          const onUpdateUserData = async () => {
            try {
              try {
                await userData.reference.update(updateUserData)
              }
              catch (error) {
                commit(
                  'CREATE_ORDER_ERROR',
                  error,
                )
              }

              if (isShowSuccessDialog)
                commit('CREATE_ORDER_SUCCESS')
            }
            catch (error) {
              console.log('error', error)
              commit(
                'CREATE_ORDER_ERROR',
                error,
              )
            }
          }

          const data1 = {
            companyRef: company.reference.id,
            email: userData.email,
            phone: userData.phone,
            languageCode: locale,
            communicationMethod: order.communicationMethod,
            title: company.name,
            completementStatus: order.completementStatus,
          }

          if (userData.notificationPlayerId)
            data1.notificationPlayerId = userData.notificationPlayerId

          if (userData.notificationToken)
            data1.notificationToken = userData.notificationToken

          if (order.completementStatus === Order.completementStatus.completed && order.isOfficeOrder)
            data1.orderTime = dayjs(supplier?.arrivalTime.toDate()).format('HH:mm')

          const promises = [orderCount]

          await getAxiosFirebase2(userData)
            .post(
              FirebaseFunctionsNames.sentNotificationToUser,
              data1,
            )

          if (serverTime && serverTime.getHours() < 17 && serverTime.getHours() >= 6) {
            promises.push(getAxiosFirebase(userData)
              .post(
                FirebaseFunctionsNames.groupOrderRequestOrCancelled,
                callData,
              ))
          }

          if (supplierPendingOrders)
            promises.push(supplierPendingOrders)

          if (supplierApprovedOrders)
            promises.push(supplierApprovedOrders)

          if (routePendingOrders)
            promises.push(routePendingOrders)

          try {
            await Promise.all(promises)
          }
          catch (error) {
            commit(
              'CREATE_ORDER_ERROR',
              error,
            )
          }
          await onUpdateUserData()
        }

        const approved = ({ status }) => status === Order.status.approved
        const countAndPrice = ({ count, price }) => count * price

        const sumFunc = (price1, price2) => Number(price1) + Number(price2)
        const amount = order.orders.filter(approved).map(countAndPrice)
          .reduce(
            sumFunc,
            0,
          )

        const correctAmount = order.paymentMethod === Order.paymentMethod.subsidyCardOnline || order.paymentMethod === Order.paymentMethod.subsidyBlik
          ? amount - supplier.subsidy.subsidyPrice
          : amount

        const payCartOnline = order.paymentMethod === Order.paymentMethod.cardOnline || order.paymentMethod === Order.paymentMethod.subsidyCardOnline

        if (payCartOnline && correctAmount > 0
        //          && userData?.paymentId?.saleId != null && !anotherCard
        ) {
          if (order.completementStatus === Order.completementStatus.completed) {
            const merchantId = Object.keys(company?.subMerchantIdsPrzelewy).includes(order?.supplierCity)
              ? company?.subMerchantIdsPrzelewy[order?.supplierCity]?.toString()
              : company?.subMerchantIdPrzelewy?.toString()

            const sessionId = `${userData.reference.id}${order.orderNumber}${Date.now() * 1000}`
            const callData = {
              merchantId,
              amount: (correctAmount * 100).toString(),
              description,
              displayName: userData.displayName,
              phone: userData.phone,
              email: userData.email,
              sessionId,
              backUrl: `https://sandwiczszop.pl/verify/${orderNumber}`,

              // orderRef: orderRef.id,
              // saleId: userData.paymentId.saleId,
              // subMerchantFee: company.subMerchantFee,
              // subMerchantIdPrzelewy: company.subMerchantIdPrzelewy,
              // userRef: userData.reference.id,
            }
            orderRef.update({
              paymentId: {
                sessionId,
              },
            })
            removeEmpty(callData)
            try {
              const test = await getAxiosFirebase(userData)
                .post(
                  FirebaseFunctionsNames.transactionRegisterPrzelewy24,
                  callData,
                )
              await onSuccess(false)
              // if (test.status == 200) {
              // window.open("https://secure.przelewy24.pl/trnRequest/${responseMap['data']['token']}")
              // window.location.href = `https://secure.przelewy24.pl/trnRequest/${test.data.data.token}`

              commit(
                'CREATE_ORDER_SUCCESS',
              )

              return ({
                token: test,
                description: null,
              })
            }
            catch (error) {
              commit(
                'CREATE_ORDER_ERROR',
                error,
              )
            }

            return ({
              token: null,
              description: null,
            })
          }

          // try {
          //   await orderRef.update({
          //     paymentId: {
          //       authorizationId: null,
          //       refundId: null,
          //       saleId: userData.paymentId.saleId,
          //     },
          //     ...onUpdate(userData.reference),
          //   })
          //   await onSuccess(true)
          // }
          // catch (error) {
          //   commit(
          //     'CREATE_ORDER_ERROR',
          //     error,
          //   )
          // }

          commit(
            'CREATE_ORDER_SUCCESS',
          )

          return ({
            token: null,
            description: null,
          })
        }
        else if (!order.isPayment) {
          await onSuccess(true)

          commit(
            'CREATE_ORDER_SUCCESS',
          )

          return ({
            token: null,
            description: null,
          })
        }
        await onSuccess(false)

        return ({
          token: null,
          description,
        })
      }
      catch (error) {
        commit(
          'CREATE_ORDER_ERROR',
          error,
        )

        return ({
          token: null,
          description: null,
        })
      }
    },
    async rePayment({ commit, rootState: { orders: { orders }, suppliers: { suppliers }, company: { company }, app: { userData } } }, { order }) {
      const supplier = suppliers.find(({ reference: { id } }) => id === order.supplier.id)
      const approved = ({ status }) => status === Order.status.approved

      const countAndPrice = ({ count, price }) => count * price

      const sumFunc = (price1, price2) => Number(price1) + Number(price2)
      const amount = order.orders.filter(approved).map(countAndPrice)
        .reduce(
          sumFunc,
          0,
        )

      const description = `Zamówienie Nr ${order.orderNumber} ${order.reference.id}`

      const merchantId = Object.keys(company?.subMerchantIdsPrzelewy).includes(order?.supplierCity)
        ? company?.subMerchantIdsPrzelewy[order?.supplierCity]?.toString()
        : company?.subMerchantIdPrzelewy?.toString()

      const correctAmount = order.paymentMethod === Order.paymentMethod.subsidyCardOnline || order.paymentMethod === Order.paymentMethod.subsidyBlik
        ? amount - supplier.subsidy.subsidyPrice
        : amount

      const sessionId = `${userData.reference.id}${order.orderNumber}${Date.now() * 1000}`

      const callData = {
        merchantId,
        amount: (correctAmount * 100).toString(),
        description,
        displayName: userData.displayName,
        phone: userData.phone,
        email: userData.email,
        sessionId,
        backUrl: `https://sandwiczszop.pl/verify/${order.orderNumber}`,

        // orderRef: orderRef.id,
        // saleId: userData.paymentId.saleId,
        // subMerchantFee: company.subMerchantFee,
        // subMerchantIdPrzelewy: company.subMerchantIdPrzelewy,
        // userRef: userData.reference.id,
      }

      try {
        const test = await getAxiosFirebase(userData)
          .post(
            FirebaseFunctionsNames.transactionRegisterPrzelewy24,
            callData,
          )

        await order.reference.update({
          paymentId: {
            sessionId,
          },
        })

        commit('GET_ORDERS_SUCCESS', orders.map(
          item => (item.reference.id === order.reference.id
            ? {
                ...item,
                paymentId: {
                  sessionId,
                },
              }
            : item),
        ))

        // if (test.status == 200) {
        // window.open("https://secure.przelewy24.pl/trnRequest/${responseMap['data']['token']}")
        // window.location.href = `https://secure.przelewy24.pl/trnRequest/${test.data.data.token}`

        return test
      }
      catch (error) {
        commit(
          'CREATE_ORDER_ERROR',
          error,
        )

        return null
      }
    },
    async verifyOrder({ commit, rootState: { suppliers: { suppliers }, company: { company }, app: { userData } } }, { order }) {
      commit('SET_VERIFY', null)
      const correctSupplier = suppliers.find(({ reference: { id } }) => id === order.supplier.id)
      const approved = ({ status }) => status === Order.status.approved

      const countAndPrice = ({ count, price }) => count * price

      const sumFunc = (price1, price2) => Number(price1) + Number(price2)
      const amount = order.orders.filter(approved).map(countAndPrice)
        .reduce(
          sumFunc,
          0,
        )

      if (!order?.paymentId?.sessionId) {
        const sessionId = `${userData.reference.id}${order.orderNumber}${Date.now() * 1000}`

        order.reference.update({
          paymentId: {
            sessionId,
          },
        })
        order.paymentId.sessionId = sessionId
      }

      const merchantId = Object.keys(company?.subMerchantIdsPrzelewy).includes(order?.supplierCity)
        ? company?.subMerchantIdsPrzelewy[order?.supplierCity]?.toString()
        : company?.subMerchantIdPrzelewy?.toString()

      const correctAmount = order.paymentMethod === Order.paymentMethod.subsidyCardOnline || order.paymentMethod === Order.paymentMethod.subsidyBlik
        ? amount - correctSupplier.subsidy.subsidyPrice
        : amount

      const description = `Zamówienie nr ${order.orderNumber} ${order.reference.id}`
      const callData = {
        merchantId,
        amount: (correctAmount * 100).toString(),
        description,
        displayName: userData.displayName,
        phone: userData.phone,
        email: userData.email,
        sessionId: order.paymentId.sessionId,
      }

      return await getAxiosFirebase(userData)
        .post(
          FirebaseFunctionsNames.transactionVerifyPrzelewy24,
          callData,
        )
        .then((response) => {
          commit('SET_VERIFY', response)

          return response
        })
        .catch((error) => {
          console.log('error', error)
          commit('SET_VERIFY', error)

          return error
        })
    },
    successPayment({ rootState: { app: { userData } } }, { idSale, idAuthorization }) {
      let paymentData = null
      if (idSale) {
        paymentData = {
          'paymentId.saleId': Number(idSale),
          ...onUpdate(userData.reference),
        }
      }
      else if (idAuthorization) {
        paymentData = {
          'paymentId.authorizationId': Number(idAuthorization),
          ...onUpdate(userData.reference),
        }
      }
      const batch = db.batch()
      batch.update(
        userData.reference,
        paymentData,
      ).commit()
        .then(() => { })
    },
    getOrders({ commit, rootState: { company: { company } } }, userData) {
      commit('GET_ORDERS_REQUEST')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_ORDERS_SUCCESS',
          docs.map(mapOrders),
        )
      }
      const onError = () => {
        commit('GET_ORDERS_ERROR')
      }

      const date = new Date()
      date.setDate(date.getDate() - 1)

      collectionOrders
        .where(
          'user',
          '==',
          userData.reference,
        )
        .where(
          'company',
          '==',
          company.reference,
        )
        .where(
          'progress',
          '==',
          'processing',
        )
        .onSnapshot(
          onSuccess,
          onError,
        )
    },
    getOrdersDelivers({ commit, rootState: { orders: { orders } } }) {
      if (!orders || orders.length === 0)
        return

      commit('GET_DELIVERS_REQUEST')

      const onSuccess = (data) => {
        commit(
          'GET_DELIVERS_SUCCESS',
          data.map(item => ({
            ...item.data(),
            reference: item.ref,
          })),
        )
      }

      const onError = () => {
        commit('GET_DELIVERS_ERROR')
      }

      const tmp = []
      new Set(orders.map(order => order.deliver.id)).forEach((id) => {
        tmp.push(db.doc(`delivers/${id}`).get())
      })

      Promise.all(tmp).then(onSuccess)
        .catch(onError)
    },
    cancelOrder({ commit, rootState: { app: { userData }, company: { company } } }, { order, supplier }) {
      let refoundResponse = null
      if (order.paymentStatus === 'success') {
        const approved = ({ status }) => status === Order.status.approved
        const countAndPrice = ({ count, price }) => count * price

        const sumFunc = (price1, price2) => Number(price1) + Number(price2)
        const amount = order.orders.filter(approved).map(countAndPrice)
          .reduce(
            sumFunc,
            0,
          )

        const correctAmount = order.paymentMethod === Order.paymentMethod.subsidyCardOnline || order.paymentMethod === Order.paymentMethod.subsidyBlik
          ? amount - supplier.subsidy.subsidyPrice
          : amount

        const merchantId = Object.keys(company?.subMerchantIdsPrzelewy).includes(order?.supplierCity)
          ? company?.subMerchantIdsPrzelewy[order?.supplierCity]?.toString()
          : company?.subMerchantIdPrzelewy?.toString()

        const callDataPaymentRefound = {
          orderId: order.paymentId.orderId,
          sessionId: order.paymentId.sessionId || '',
          merchantId,
          description: `Odrzucono ${order.orderNumber.toString()}`,
          userRef: userData.reference.id,
          refundType: 'refund',
          requestId: `${userData.reference.id.toString()}${order.orderNumber.toString()}${Date.now() * 1000}`,
          amount: (correctAmount * 100).toString(),
          companyRef: company.reference.id,
          companyName: company.name,
        }
        console.log('callDataPaymentRefound', callDataPaymentRefound)
        refoundResponse = getAxiosFirebase(userData)
          .post(
            FirebaseFunctionsNames.transactionRefundPrzelewy24,
            callDataPaymentRefound,
          )
        console.log('refoundResponse', refoundResponse)
      }

      const callData = {
        order: JSON.stringify({
          userRef: userData.reference.id,
          sellsPointName: supplier?.place?.name,
          sellsAddress: supplier?.place?.address,
          deliverID: order.deliver.id,
          notificationType: 'order_cancelled',
        }),
      }

      const notification = getAxiosFirebase(userData)
        .post(
          FirebaseFunctionsNames.groupOrderRequestOrCancelled,
          callData,
        )

      const batch = db.batch()

      const updateOrder = batch.update(
        order.reference,
        {
          completementStatus: 'cancelled',
          ...onUpdate(userData.reference),
        },
      ).commit()
      const supplierPendingOrders = order.supplier.update({
        pendingOrders: FieldValue.increment(-1),
        ...onUpdate(userData.reference),
      })
      const routePendingOrders = order.route.update({
        pendingOrders: FieldValue.increment(-1),
        ...onUpdate(userData.reference),
      })

      const onSuccess = () => {
        commit(
          'SET_STATUS_WHOLE_ORDER',
          {
            order,
            completementStatus: 'cancelled',
          },
        )
      }

      const onError = () => { }
      if (refoundResponse) {
        Promise.all([
          refoundResponse,
          updateOrder,
          notification,
          supplierPendingOrders,
          routePendingOrders,
        ])
          .then(onSuccess)
          .catch(onError)
      }
      else {
        Promise.all([
          updateOrder,
          notification,
          supplierPendingOrders,
          routePendingOrders,
        ])
          .then(onSuccess)
          .catch(onError)
      }
    },
    approveOrder({ commit, rootState: { app: { userData } } }, { order, supplier }) {
      const callData = {
        order: JSON.stringify({
          userRef: userData.reference.id,
          sellsPointName: supplier?.place?.name,
          sellsAddress: supplier?.place?.address,
          deliverID: order.deliver.id,
          notificationType: 'order_confirm',
        }),
      }

      const notification = getAxiosFirebase(userData)
        .post(
          FirebaseFunctionsNames.groupOrderRequestOrCancelled,
          callData,
        )

      const batch = db.batch()

      const orders = order.orders.map((item) => {
        if (item.status === 'rejected')
          item.status = 'rejectedApprovedByCustomer'
        else if (item.status === 'proposeByDeliver')
          item.status = 'pending'

        return item
      })
      const updateOrder = batch.update(
        order.reference,
        {
          completementStatus: 'waitingFirmAccept',
          orders: orders.map(product => product.toMap()),
          ...onUpdate(userData.reference),
        },
      ).commit()

      const onSuccess = () => {
        commit(
          'SET_STATUS_WHOLE_ORDER',
          {
            order: {
              ...order,
              orders,
            },
            completementStatus: 'waitingFirmAccept',
          },
        )
      }
      const onError = () => { }
      Promise.all([
        updateOrder,
        notification,
      ])
        .then(onSuccess)
        .catch(onError)
    },
    removeOrderItem({ rootState: { app: { userData } } }, { order, orderItem }) {
      const batch = db.batch()
      const orders = order.orders.map((item) => {
        if (item.status === 'proposeByDeliver' && item.menuRef.id === orderItem.menuRef.id)
          item.status = 'cancelled'

        return item
      })
      batch.update(
        order.reference,
        {
          orders: orders.map(product => product.toMap()),
          ...onUpdate(userData.reference),
        },
      ).commit()
        .then()
    },
    selectedOrder({ commit, rootState: { suppliers: { suppliers } } }, selectedOrder) {
      commit(
        'SET_SELECTED_ORDER',
        selectedOrder,
      )
      const byId = item => item.reference.id === selectedOrder.supplier.id
      const supplier = suppliers.find(byId)

      localStorage.selectSupplier = supplier.reference.id
      localStorage.cart = JSON.stringify(selectedOrder.orders.map(({ count, menuRef }) => ({
        item: menuRef.id,
        quantity: count,
      })))
      store.dispatch(
        'suppliers/selectSupplier',
        supplier,
      )

      router.replace('/menu').catch(() => { })
    },
    removeSelectedOrder({ commit }, selectedOrder) {
      commit(
        'SET_SELECTED_ORDER',
        selectedOrder,
      )
      localStorage.selectSupplier = null
      store.dispatch(
        'suppliers/selectSupplier',
        null,
      )
    },
    async updateOrder({ commit, rootState: { app: { userData } } }, { order, cart, supplier, orderSelected, anotherCard }) {
      commit('UPDATE_ORDER_REQUEST')

      const mapToProductModel = ({ item, quantity }) => {
        if (item?.count) {
          if (item.status === 'rejected') {
            return {
              ...item.toMap(),
              count: quantity,
              status: 'rejectedApprovedByCustomer',
            }
          }
          else if (item.status === 'proposeByDeliver') {
            return {
              ...item.toMap(),
              count: quantity,
              status: 'pending',
            }
          }

          return {
            ...item.toMap(),
            count: quantity,
          }
        }
        const { image, nameEN, name, reference, price, categoryID } = item

        return new ProductModel({
          count: quantity,
          image,
          menuName: name,
          menuNameEN: nameEN,
          menuRef: reference,
          price,
          categoryID,
          status: 'pending',
        }).toMap()
      }

      const orders = cart.map(mapToProductModel)
      let newOrder = {
        ...order.toMap(),
        orders,
        paymentId: orderSelected.paymentId,
        orderNumber: orderSelected.orderNumber,
        completementStatus: 'waitingFirmAccept',
      }
      let updateUserData = {
        displayName: order.customerName,
        phone: order.phone,
      }
      let notification = null
      let supplierPendingOrders = null
      let routePendingOrders = null
      let orderCount = null
      if (orderSelected.supplier.id !== order.supplier.id) {
        const callData = {
          order: JSON.stringify({
            userRef: userData.reference.id,
            sellsPointName: supplier?.place?.name,
            sellsAddress: supplier?.place?.address,
            deliverID: order?.deliver?.id,
            notificationType: 'order_request',
          }),
        }
        notification = getAxiosFirebase(userData)
          .post(
            FirebaseFunctionsNames.groupOrderRequestOrCancelled,
            callData,
          )
        supplierPendingOrders = order.supplier.update({
          pendingOrders: FieldValue.increment(1),
          ...onUpdate(userData.reference),
        })
        routePendingOrders = order.route.update({
          pendingOrders: FieldValue.increment(1),
          ...onUpdate(userData.reference),
        })
        orderCount = order.route.update({
          ordersCount: FieldValue.increment(1),
          ...onUpdate(userData.reference),
        })
      }
      if (!order.isOfficeOrder) {
        updateUserData = {
          ...updateUserData,
          home: new AddressModel(order.address).toCustomerHomeMap(),
          ...onUpdate(userData.reference),
        }
      }

      const isOnlineCardPayment = order.paymentMethod === Order.paymentMethod.cardOnline || order.paymentMethod === Order.paymentMethod.subsidyCardOnline

      if (
        !orderSelected.isPayment
        && isOnlineCardPayment
        && userData?.paymentId?.saleId != null && !anotherCard) {
        newOrder = {
          ...newOrder,
          paymentId: {
            authorizationId: null,
            refundId: null,
            saleId: userData.paymentId.saleId,
          },
          ...onUpdate(userData.reference),
        }
      }
      else if (
        !orderSelected.isPayment
        && isOnlineCardPayment) {
        try {
          await orderSelected.reference.update(newOrder)
          store.dispatch('menu/clearCart')
          await userData.reference.update(updateUserData)

          return `Zamówienie Nr ${orderSelected.orderNumber} ${orderSelected.reference.id}`
        }
        catch (error) {
          commit('UPDATE_ORDER_ERROR')
        }
      }

      const onSuccess = () => {
        store.dispatch('menu/clearCart')
        Object.keys(updateUserData).forEach(value => store.dispatch(
          'app/updateUserData',
          {
            key: value,
            value: updateUserData[value],
          },
        ))

        const onSuccessFinish = () => {
          commit('UPDATE_ORDER_SUCCESS')
        }
        const onErrorFinish = () => {
          commit('UPDATE_ORDER_ERROR')
        }
        userData.reference.update(updateUserData)
          .then(onSuccessFinish)
          .catch(onErrorFinish)
      }
      const promises = [orderSelected.reference.update(newOrder)]

      if (notification) {
        promises.push(notification)
        promises.push(supplierPendingOrders)
        promises.push(routePendingOrders)
        promises.push(orderCount)
      }
      Promise.all(promises).then(onSuccess)

      return null
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    SET_VERIFY(_state, data) {
      _state.verifyOrderResponse = data
    },

    CREATE_ORDER_REQUEST(_state) {
      _state.loadingCreateOrder = true
      _state.error = null
    },
    CREATE_ORDER_SUCCESS(_state) {
      _state.loadingCreateOrder = false
    },
    CREATE_ORDER_ERROR(_state, error) {
      _state.loadingCreateOrder = false
      _state.error = error
    },

    GET_INVOICE_REQUEST(_state) {
      _state.invoiceLoading = true
      _state.invoiceError = null
    },
    GET_INVOICE_SUCCESS(_state, invoiceData) {
      _state.invoiceLoading = false
      _state.invoiceData = invoiceData
    },
    GET_INVOICE_ERROR(_state, error) {
      _state.invoiceLoading = false
      _state.invoiceError = error
    },

    GET_USER_INVOICE_REQUEST(_state) {
      _state.invoiceUserLoading = true
      _state.invoiceUserError = null
    },
    GET_USER_INVOICE_SUCCESS(_state, invoiceData) {
      _state.invoiceUserLoading = false
      _state.invoiceUserData = invoiceData
    },
    GET_USER_INVOICE_ERROR(_state, error) {
      _state.invoiceUserLoading = false
      _state.invoiceUserError = error
    },

    UPDATE_ORDER_REQUEST(_state) {
      _state.loadingCreateOrder = true
      _state.error = null
    },
    UPDATE_ORDER_SUCCESS(_state) {
      _state.selectedOrder = null
      _state.loadingCreateOrder = false
    },
    UPDATE_ORDER_ERROR(_state, error) {
      _state.loadingCreateOrder = false
      _state.error = error
    },

    GET_ORDERS_REQUEST(_state) {
      _state.loadingOrders = true
    },
    GET_ORDERS_SUCCESS(_state, orders = []) {
      _state.loadingOrders = false
      _state.orders = orders
    },
    GET_ORDERS_ERROR(_state) {
      _state.loadingOrders = false
    },

    GET_DELIVERS_REQUEST(_state) {
      _state.loadingOrders = true
    },
    GET_DELIVERS_SUCCESS(_state, delivers = []) {
      _state.loadingOrders = false
      _state.ordersDelivers = delivers
    },
    GET_DELIVERS_ERROR(_state) {
      _state.loadingOrders = false
    },

    SET_ORDERS(state, orders) {
      state.orders = orders
    },

    SET_SELECTED_ORDER(state, selectedOrder) {
      state.selectedOrder = selectedOrder
    },

    SET_LOADING_ORDER(state, { order, index, loading }) {
      state.orders = state.orders.map(setLoadingProduct(
        order,
        index,
        'loading',
        loading,
      ))
    },

    SET_LOADING_WHOLE_ORDER(state, { order, loading }) {
      state.orders = state.orders.map(updateOrder(
        order,
        'loading',
        loading,
      ))
    },

    SET_STATUS_WHOLE_ORDER(state, { order, completementStatus }) {
      state.orders = state.orders.map(updateOrder(
        order,
        'completementStatus',
        completementStatus,
      ))
    },
  },
}
