import { withoutEmptyString } from '~/helpers/text'

export class WorkModel {
  company: any
  suppliers: any
  firmRequests: any
  floor: any
  geoPoint: any
  parking: any
  address: any
  city: any
  buyOnlineRequest: any
  arrivingTime: any
  employeesCount: any
  subsidyIdentifier: any
  constructor(data) {
    this.company = data?.company || null
    this.suppliers = data?.suppliers || []
    this.firmRequests = data?.firmRequests || null
    this.floor = data?.floor || null
    this.geoPoint = data?.geoPoint || null
    this.parking = data?.parking || null
    this.address = data?.address || null
    this.city = data?.city || null
    this.buyOnlineRequest = data?.buyOnlineRequest || null
    this.arrivingTime = data?.arrivingTime || null
    this.employeesCount = data?.employeesCount || null
    this.subsidyIdentifier = data?.subsidyIdentifier || null
  }

  toMap() {
    return {
      company: withoutEmptyString(this.company),
      suppliers: this.suppliers || [],
      firmRequests: withoutEmptyString(this.firmRequests),
      floor: withoutEmptyString(this.floor),
      geoPoint: this.geoPoint || null,
      parking: this.parking || null,
      address: withoutEmptyString(this.address),
      city: withoutEmptyString(this.city),
      buyOnlineRequest: this.buyOnlineRequest || null,
      arrivingTime: this.arrivingTime || null,
      subsidyIdentifier: this.subsidyIdentifier || null,
      employeesCount: withoutEmptyString(this.employeesCount),
    }
  }
}
