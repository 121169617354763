function initialState() {
  return {
    visible: false,
    showCartStore: false,
  }
}

export default {
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    hideDrawer({ commit }) {
      commit('HIDE_DRAWER')
    },
    showDrawer({ commit }) {
      commit('SHOW_DRAWER')
    },

    hideCart({ commit }) {
      commit('HIDE_CART')
    },
    showCart({ commit }) {
      commit('SHOW_CART')
    },
  },
  getters: {
    drawer: drawer => drawer,
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    HIDE_DRAWER(_state) {
      _state.visible = false
    },
    SHOW_DRAWER(_state) {
      _state.visible = true
    },
    HIDE_CART(_state) {
      _state.showCartStore = false
    },
    SHOW_CART(_state) {
      _state.showCartStore = true
    },
  },
  namespaced: true,
  state: initialState(),
}
