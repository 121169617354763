import Vue from 'vue'
import Vuex from 'vuex'
import notifications from './notifications'
import app from './app'
import company from './company'
import drawer from './drawer'
import menu from './menu'
import messages from './messages'
import orders from './orders'
import serverTime from './serverTime'
import updateApplication from './updateApplication'
import suppliers from './suppliers'
import reportBugOrSuggestion from './reportBugOrSuggestion'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    messages,
    menu,
    company,
    suppliers,
    notifications,
    serverTime,
    orders,
    drawer,
    updateApplication,
    reportBugOrSuggestion,
  },
})

export default store
