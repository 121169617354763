import { SHOP_ID, collectionMenu, collectionMenuCategories, db } from '../db'
import router from '../router'
import { mapMenuCategories } from '~/models/MenuCategoryModel'
import { mapMenuModel } from '~/models/MenuModel'

function initialState() {
  return {
    menuCategories: null,
    menuCategoriesLoading: false,

    menus: null,
    fetchLoading: false,

    menusNextDay: null,
    fetchNextDayLoading: false,

    nextDay: null,
    selectedNextDayMenu: false,

    cart: null,
    cartEdit: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async getMenu({
      commit,
    }, day) {
      let newMenus = null
      commit('GET_MENUS_REQUEST')
      commit('GET_MENU_CATEGORIES_REQUEST')

      const onError = () => {
        commit('GET_MENUS_CATEGORIES_FAILURE')

        commit('GET_MENUS_FAILURE')
      }

      const onSuccess = (value) => {
        const [
          menuCategoriesData,
          menuData,
        ] = value

        commit(
          'GET_MENUS_CATEGORIES_SUCCESS',
          mapMenuCategories(menuCategoriesData),
        )

        commit(
          'GET_MENUS_SUCCESS',
          menuData.docs.map(mapMenuModel),
        )
        newMenus = menuData.docs.map(mapMenuModel)
      }

      const menuCategoriesCb = collectionMenuCategories.doc(SHOP_ID).get()

      const categoryDocRef = await db
        .collection('companies')
        .doc(SHOP_ID)

      const menuCb = collectionMenu
        .where(
          'companyRef',
          '==',
          categoryDocRef,
        )
        .where(
          'isActive',
          '==',
          true,
        )
        .where(
          'activeDays',
          'array-contains',
          day,
        )
        .get()

      Promise.all([
        menuCategoriesCb,
        menuCb,
      ])
        .then(onSuccess)
        .catch(onError)

      return newMenus
    },
    async getMenuNext({
      commit,
    }, day) {
      commit('GET_MENUS_NEXT_REQUEST')

      const onError = () => {
        commit('GET_MENUS_NEXT_FAILURE')
      }

      const onSuccess = (menuData) => {
        commit(
          'GET_MENUS_NEXT_SUCCESS',
          menuData.docs.map(mapMenuModel),
        )

        return menuData.docs.map(mapMenuModel)
      }

      const categoryDocRef = await db
        .collection('companies')
        .doc(SHOP_ID)

      const menuCb = collectionMenu
        .where(
          'companyRef',
          '==',
          categoryDocRef,
        )
        .where(
          'isActive',
          '==',
          true,
        )
        .where(
          'activeDays',
          'array-contains',
          day,
        )
        .get()

      return menuCb
        .then(onSuccess)
        .catch(onError)
    },
    async getMenuAndCheck({
      commit,
    }, { day, selectedNextDayMenu }) {
      commit('GET_MENUS_REQUEST')
      commit('GET_MENU_CATEGORIES_REQUEST')

      const onError = () => {
        commit('GET_MENUS_CATEGORIES_FAILURE')
        commit('GET_MENUS_FAILURE')
      }

      const onSuccess = ([
        menuCategoriesData,
        menuData,
      ]) => {
        commit(
          'GET_MENUS_CATEGORIES_SUCCESS',
          mapMenuCategories(menuCategoriesData),
        )
        if (selectedNextDayMenu) {
          commit(
            'GET_MENUS_NEXT_SUCCESS',
            menuData.docs.map(mapMenuModel),
          )
        }
        else {
          commit(
            'GET_MENUS_SUCCESS',
            menuData.docs.map(mapMenuModel),
          )
        }

        return menuData.docs.map(mapMenuModel)
      }

      const menuCategoriesCb = collectionMenuCategories
        .doc(SHOP_ID)
        .get()

      const categoryDocRef = await db
        .collection('companies')
        .doc(SHOP_ID)

      const menuCb = collectionMenu
        .where(
          'companyRef',
          '==',
          categoryDocRef,
        )
        .where(
          'isActive',
          '==',
          true,
        )
        .where(
          'activeDays',
          'array-contains',
          day,
        )
        .get()

      return Promise.all([
        menuCategoriesCb,
        menuCb,
      ])
        .then(onSuccess)
        .catch(onError)
    },

    saveCart({ commit }, { cart, nextDay, selectedNextDayMenu }) {
      commit(
        'UPDATE_CART',
        cart,
      )
      commit(
        'UPDATE_NEXT_DAY',
        {
          nextDay,
          selectedNextDayMenu,
        },
      )
      router.replace('/summary').catch(() => { })
    },
    onlySaveCart({ commit }, { cart, nextDay, selectedNextDayMenu }) {
      commit(
        'UPDATE_CART',
        cart,
      )
      commit(
        'UPDATE_NEXT_DAY',
        {
          nextDay,
          selectedNextDayMenu,
        },
      )
    },
    clearCart({ commit }) {
      commit(
        'UPDATE_CART',
        null,
      )
      commit(
        'UPDATE_NEXT_DAY',
        null,
      )
    },
  },
  getters: {
    menus: ({ menus }) => menus,
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        {
          cart: null,
          cartEdit: null,
        },
      )
    },

    GET_MENU_CATEGORIES_REQUEST(_state) {
      _state.menuCategoriesLoading = true
    },
    GET_MENUS_CATEGORIES_SUCCESS(_state, menuCategories) {
      _state.menuCategoriesLoading = false
      _state.menuCategories = menuCategories
    },
    GET_MENUS_CATEGORIES_FAILURE(_state) {
      _state.menuCategoriesLoading = false
    },

    GET_MENUS_REQUEST(_state) {
      _state.fetchLoading = true
    },
    GET_MENUS_SUCCESS(_state, menus) {
      _state.menus = menus
      _state.fetchLoading = false
    },
    GET_MENUS_FAILURE(_state) {
      _state.fetchLoading = false
    },

    GET_MENUS_NEXT_REQUEST(_state) {
      _state.fetchNextDayLoading = true
    },
    GET_MENUS_NEXT_SUCCESS(_state, menus) {
      _state.menusNextDay = menus
      _state.fetchLoading = false
      _state.fetchNextDayLoading = false
    },
    GET_MENUS_NEXT_FAILURE(_state) {
      _state.fetchNextDayLoading = false
    },

    UPDATE_NEXT_DAY(_state, data) {
      _state.nextDay = data?.nextDay || null
      _state.selectedNextDayMenu = data?.selectedNextDayMenu || false
    },
    UPDATE_CART(_state, cart) {
      _state.cart = cart
    },
    UPDATE_CART_EDIT(_state, cart) {
      _state.cartEdit = cart
    },
  },
}
