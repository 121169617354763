import Vue from 'vue'
import { auth } from './db'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

let app: any = null

auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      name: 'App',
      router,
      store,
      vuetify,
      i18n,
      render: func => func(App),
    }).$mount('#app')
  }

  if (user) {
    store.dispatch(
      'app/fetchUserProfile',
      user,
    )
  }
})
