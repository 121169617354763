import { withoutEmptyString } from '~/helpers/text'

export class AddressModel {
  postCode: any
  address: any
  city: any
  flatNumber: any
  floor: any
  doorCode: any
  description: any
  geoPoint: any
  street: any
  constructor(data) {
    this.postCode = data.postCode || null
    this.address = data.address || null
    this.city = data.city || null
    this.flatNumber = data.flatNumber || null
    this.floor = data.floor || null
    this.doorCode = data.doorCode || null
    this.description = data.description || null
    this.geoPoint = data.geoPoint || null
    this.street = data.street || null
  }

  toMap() {
    return {
      postCode: withoutEmptyString(this.postCode?.trim()),
      street: withoutEmptyString(this.street),
      city: withoutEmptyString(this.city),
    }
  }

  toCustomerHomeMap() {
    return {
      postCode: this.postCode?.trim() || null,
      address: this.address?.trim() || null,
      city: this.city?.trim() || null,
      flatNumber: this.flatNumber?.trim() || null,
      floor: this.floor?.trim() || null,
      doorCode: this.doorCode?.trim() || null,
      description: this.description?.trim() || null,
      geoPoint: this.geoPoint || null,
    }
  }
}
