<script lang="ts">
import 'leaflet/dist/leaflet.css'
import { mapGetters, mapState } from 'vuex'
import { mdiCheckCircleOutline, mdiCrosshairsGps, mdiMinus, mdiPlus } from '@mdi/js'
import { Icon } from 'leaflet'
import { mask } from 'vue-the-mask'
import {
  phoneRuleNotRequired,
  postCodeMask,
  postCodeRule,
  requiredRule,
} from '../../helpers/rules'
import { Timestamp } from '../../db'
import { formatPhoneToDatabase } from '../../helpers/text'

delete Icon.Default.prototype._getIconUrl

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: '/img/leaflet/marker-icon-2x.png',
  iconUrl: '/img/leaflet/marker-icon.png',
  shadowUrl: '/img/leaflet/marker-shadow.png',
})

export default {
  name: 'ContactForm',

  directives: {
    mask,
  },

  data: () => ({
    mdiCrosshairsGps,
    mdiCheckCircleOutline,
    mdiPlus,
    mdiMinus,

    phoneMask: '+## ###-###-###',
    postCodeMask,
    valid: true,
    showDialog: false,

    address: null,
    city: null,
    postCode: null,
    companyName: null,
    message: null,
    phone: '+48 ',
  }),

  computed: {
    ...mapState(
      'company',
      ['company'],
    ),

    ...mapGetters({
      loading: 'messages/messagesLoading',
      error: 'messages/messagesError',
      success: 'messages/messagesSuccess',
    }),

    getPadding() {
      return document.documentElement.clientWidth > 600
        ? 'py-12'
        : 'py-0'
    },
  },

  watch: {
    loading(newLoading, oldLoading) {
      if (!newLoading && oldLoading && !this.error) {
        this.showDialog = true
        this.address = null
        this.city = null
        this.postCode = null
        this.companyName = null
        this.message = null
        this.$refs.form.reset()
        this.phone = '+48 '
      }
    },
  },

  methods: {
    requiredRule,
    postCodeRule,
    phoneRuleNotRequired,

    onSent() {
      this.valid = this.$refs.form.validate()
      if (this.valid) {
        this.$store.dispatch(
          'messages/sendContactForm',
          {
            data: {
              company: this.companyName || null,
              phone: formatPhoneToDatabase(this.phone),
              city: this.city,
              address: this.address,
              postCode: this.postCode,
              message: this.message || null,
              companyRef: this.company.reference,
              status: 'active',
              date: Timestamp.now(),
            },
          },
        )
      }
    },

    onInput(value) {
      this.showDialog = value
    },

    closeDialog() {
      this.showDialog = false
      this.phone = '+48 '
    },
  },
}
</script>

<template>
  <v-container
    id="contact"
    :class="getPadding"
  >
    <v-form
      ref="form"
      v-model="valid"
      class="pt-12"
      lazy-validation
      @submit.prevent="onSent"
    >
      <v-container
        style="max-width: 1000px;"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <h1>{{ $t("ContactForm.title") }}</h1>

            <br>

            <h3>{{ $t("ContactForm.subtitle") }}</h3>

            <br>

            <br>

            <p
              class="mb-0"
            >
              {{ $t("ContactForm.address") }}
            </p>

            <v-text-field
              v-model="address"
              :rules="[requiredRule]"
              single-line
              :placeholder="$t('ContactForm.addressPlaceholder')"
              outlined
            />

            <v-row>
              <v-col
                cols="12"
                sm="7"
                md="7"
                lg="7"
              >
                <p
                  class="mb-0"
                >
                  {{ $t("ContactForm.city") }}
                </p>

                <v-text-field
                  v-model="city"
                  :placeholder="$t('ContactForm.cityPlaceholder')"
                  :rules="[requiredRule]"
                  single-line
                  outlined
                />

                <p
                  class="mb-0"
                  style="margin-top: 11px"
                >
                  {{ $t("ContactForm.phone") }}
                </p>

                <v-text-field
                  v-model="phone"
                  v-mask="phoneMask"
                  :rules="[phoneRuleNotRequired]"
                  outlined
                />

                <v-row
                  class="d-flex d-sm-none mb-0 pb-0"
                >
                  <v-col
                    cols="12"
                  >
                    <p
                      class="mb-0"
                    >
                      {{ $t("ContactForm.postCode") }}
                    </p>

                    <v-text-field
                      v-model="postCode"
                      v-mask="postCodeMask"
                      placeholder="00-000"
                      :rules="[
                        requiredRule,
                        postCodeRule,
                      ]"
                      single-line
                      outlined
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    class="my-0 py-0"
                  >
                    <p
                      class="mb-0"
                    >
                      {{ $t("ContactForm.companyName") }}
                    </p>

                    <v-text-field
                      v-model="companyName"
                      single-line
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                sm="5"
                md="5"
                lg="5"
                class="d-none d-sm-flex"
              >
                <v-row
                  no-gutters
                >
                  <v-col
                    cols="6"
                    sm="12"
                    md="12"
                    lg="12"
                  >
                    <p
                      class="mb-0"
                      style="margin-top: 6px"
                    >
                      {{ $t("ContactForm.postCode") }}
                    </p>

                    <v-text-field
                      v-model="postCode"
                      v-mask="postCodeMask"
                      placeholder="00-000"
                      :rules="[
                        requiredRule,
                        postCodeRule,
                      ]"
                      single-line
                      outlined
                    />

                    <p
                      class="mb-0"
                      style="margin-top: 6px"
                    >
                      {{ $t("ContactForm.companyName") }}
                    </p>

                    <v-text-field
                      v-model="companyName"
                      single-line
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <p
          class="mb-2 mt-0 pt-0"
        >
          {{ $t("ContactForm.message") }}
        </p>

        <v-textarea
          v-model="message"
          outlined
        />

        <v-row
          class="mx-1"
        >
          <v-spacer />

          <v-btn
            :loading="loading"
            width="200"
            x-large
            outlined
            color="primary"
            @click="onSent"
          >
            <span
              class="font-weight-bold text-none"
            >{{ $t("ContactForm.send") }}</span>
          </v-btn>
        </v-row>
      </v-container>
    </v-form>

    <DefaultDialog
      :value="showDialog"
      :on-input="onInput"
      :on-close="closeDialog"
      :icon="mdiCheckCircleOutline"
      :title="$t('ContactForm.dialogTitle')"
      :sub-title="$t('ContactForm.dialogSubTitle')"
      button-text="OK"
    />
  </v-container>
</template>

<style scoped>
a {
  text-decoration: none;
}
</style>
