import { defineStore } from 'pinia'
import { ref } from 'vue'
import { collectionUpdateApplication } from '../db'
import { mapUpdateApplication } from '~/models/UpdateApplicationModel'

export const useUpdateApplicationStore = defineStore('updateApplication', () => {
  const updateApplication = ref(null)
  const loadingUpdateApplication = ref(false)
  const errorUpdateApplication = ref(null)

  const resetState = () => {
    updateApplication.value = null
    loadingUpdateApplication.value = false
    errorUpdateApplication.value = null
  }

  const getUpdateApplication = () => {
    loadingUpdateApplication.value = true

    const onSuccess = (data) => {
      loadingUpdateApplication.value = false
      updateApplication.value = data
    }
    const onError = (error) => {
      loadingUpdateApplication.value = false
      errorUpdateApplication.value = error
    }

    collectionUpdateApplication
      .doc('/updateApplication')
      .get()
      .then(onSuccess)
      .catch(onError)
  }

  return {
    updateApplication,
    loadingUpdateApplication,
    errorUpdateApplication,
    resetState,
    getUpdateApplication,
  }
})

function initialState() {
  return {
    updateApplication: null,
    loadingUpdateApplication: false,
    errorUpdateApplication: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getUpdateApplication({ commit }) {
      commit('GET_UPDATE_APPLICATION_REQUEST')

      const onSuccess = (data) => {
        commit(
          'GET_UPDATE_APPLICATION_SUCCESS',
          mapUpdateApplication(data),
        )
      }
      const onError = (error) => {
        commit(
          'GET_UPDATE_APPLICATION_ERROR',
          error,
        )
      }

      collectionUpdateApplication
        .doc('/updateApplication')
        .get()
        .then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_UPDATE_APPLICATION_REQUEST(_state) {
      _state.loadingUpdateApplication = true
    },
    GET_UPDATE_APPLICATION_SUCCESS(_state, updateApplication = {}) {
      _state.loadingUpdateApplication = false
      _state.updateApplication = updateApplication
    },
    GET_UPDATE_APPLICATION_ERROR(_state, error) {
      _state.loadingUpdateApplication = false
      _state.errorUpdateApplication = error
    },
  },
}
