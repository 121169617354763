import { reportBugOrSuggestionCollection } from '../db'

function initialState() {
  return {
    reportBugOrSuggestions: null,
    reportBugOrSuggestionError: null,
    reportBugOrSuggestionLoading: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    sendCompanySuggestion({
      commit,
    }, { data }) {
      commit('SEND_REPORT_BUG_OR_SUGGESTIONS_REQUEST')

      const onSuccess = () => {
        commit('SEND_REPORT_BUG_OR_SUGGESTION_STATE_SUCCESS')
      }

      const onError = (error) => {
        commit(
          'SEND_REPORT_BUG_OR_SUGGESTION_STATE_FAILURE',
          error,
        )
      }

      reportBugOrSuggestionCollection.add(data.toMap())
        .then(onSuccess)
        .catch(onError)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },
    SEND_REPORT_BUG_OR_SUGGESTIONS_REQUEST(_state) {
      _state.reportBugOrSuggestionLoading = true
      _state.reportBugOrSuggestionError = null
    },
    SEND_REPORT_BUG_OR_SUGGESTION_STATE_SUCCESS(_state) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = null
    },
    SEND_REPORT_BUG_OR_SUGGESTION_STATE_FAILURE(_state, error) {
      _state.reportBugOrSuggestionLoading = false
      _state.reportBugOrSuggestionError = error
    },
  },
}
