import { MenuCategoryModel, mapMenuCategories } from './MenuCategoryModel'
import { MacroModel } from './MacroModel'
import i18n from '~/i18n'

export { MenuCategoryModel, mapMenuCategories }

export function defaultMacros() {
  return [
    new MacroModel({
      name: i18n.t('Macro.Energy'),
      nameEN: i18n.t('Macro.EnergyEN'),
      units: 'kcal',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Fats'),
      nameEN: i18n.t('Macro.FatsEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.FattyAcids'),
      nameEN: i18n.t('Macro.FattyAcidsEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Carbohydrates'),
      nameEN: i18n.t('Macro.CarbohydratesEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Monosaccharides'),
      nameEN: i18n.t('Macro.MonosaccharidesEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Protein'),
      nameEN: i18n.t('Macro.ProteinEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Salt'),
      nameEN: i18n.t('Macro.SaltEN'),
      units: 'g',
      value: 0,
    }),
  ]
}

export class MenuModel {
  foodSort: any
  description: any
  descriptionEN: any
  image: any
  imageHigh: any
  isActive: any
  name: any
  nameEN: any
  macro: any
  activeDays: any
  price: any
  weight: any
  likes: any
  favourites: any
  imagePath: any
  imageHighPath: any
  companyRef: any
  categoryID: any
  createdByUser: any
  lastUpdateByUser: any
  lastUpdateTime: any
  reference: any
  constructor(data, reference) {
    const mapMacro = macro => new MacroModel(macro)

    this.foodSort = data.foodSort || null
    this.description = data.description || null
    this.descriptionEN = data.descriptionEN || null
    this.image = data.image || null
    this.imageHigh = data.imageHigh || null
    this.isActive = data.isActive || false
    this.name = data.name || null
    this.nameEN = data.nameEN || null
    this.macro = data.macro
      ? data.macro.map(mapMacro)
      : []
    this.activeDays = data.activeDays || []
    this.price = data.price || null
    this.weight = data.weight || null
    this.likes = data.likes || []
    this.favourites = data.favourites || []
    this.imagePath = data.imagePath || null
    this.imageHighPath = data.imageHighPath || null
    this.companyRef = data.companyRef || null
    this.categoryID = data.categoryID || null

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null

    this.reference = reference
  }

  toMap() {
    const mapMacro = macro => macro.toMap()

    return {
      description: this.description?.trim() || null,
      descriptionEN: this.descriptionEN?.trim() || null,
      image: this.image,
      imageHigh: this.imageHigh,
      isActive: this.isActive,
      name: this.name?.trim() || null,
      nameEN: this.nameEN?.trim() || null,
      macro: this.macro.map(mapMacro),
      activeDays: this.activeDays,
      price: this.price,
      likes: this.likes,
      foodSort: this.foodSort,
      favourites: this.favourites,
      imagePath: this.imagePath,
      imageHighPath: this.imageHighPath,
      companyRef: this.companyRef,
      categoryID: this.categoryID,
      weight: this.weight,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,

    }
  }
}

export function mapMenuModel(menuData) {
  return new MenuModel(
    menuData.data(),
    menuData.ref,
  )
}

export const FOOD_SORT = [
  {
    value: 'meat',
  },
  {
    value: 'wege',
  },
  {
    value: 'vegan',
  },
  {
    value: 'fish',
  },
]
