import { FieldValue, SHOP_ID, collectionSupplier, db } from '../db'
import { onUpdate } from '../helpers/actions'
import store from '.'
import { mapSuppliers } from '~/models/SuppliersModel'

// import { useAppStore } from './app'
// import { useCompanyStore } from './company'

// export const useSuppliersStore = defineStore('suppliersStore', () => {
//   const loadingSuppliers = ref(false)
//   const suppliers = ref(null)
//   const errorSuppliers = ref(null)

//   const homeDeliverySuppliers = ref(null)
//   const loadingHomeDeliverySuppliers = ref(false)

//   const selectedSupplier = ref(null)
//   const selectedSupplierId = ref(null)

//   const resetState = () => {
//     suppliers.value = null
//     loadingSuppliers.value = false
//     errorSuppliers.value = null

//     homeDeliverySuppliers.value = null
//     loadingHomeDeliverySuppliers.value = false

//     selectedSupplier.value = null
//     selectedSupplierId.value = null
//   }

//   const getUserSuppliers = (userData) => {
//     if (!userData || !userData?.work?.suppliers || Array.isArray(userData?.work?.suppliers) && userData?.work?.suppliers.length === 0)
//       return

//     loadingSuppliers.value = true

//     const onSuccess = (data) => {
//       const byCompany = item => item.company.id === SHOP_ID

//       loadingSuppliers.value = false
//       suppliers.value = data.filter(byCompany)
//     }

//     const onError = () => {
//       loadingSuppliers.value = false
//     }

//     const getSupplier = supplier => supplier.get()

//     const suppliersPromises = userData.work.suppliers.map(getSupplier)

//     Promise.all(suppliersPromises)
//       .then(onSuccess)
//       .catch(onError)
//   }

//   const addSupplierByCode = (code) => {
//     const appStore = useAppStore()
//     const companyStore = useCompanyStore()

//     const { userData } = storeToRefs(appStore)
//     const { company } = storeToRefs(companyStore)

//     loadingSuppliers.value = true
//     errorSuppliers.value = null

//     const onError = (error) => {
//       loadingSuppliers.value = false
//       errorSuppliers.value = error
//     }

//     const onSuccess = (result) => {
//       const [data] = result.docs
//       if (result.empty) {
//         loadingSuppliers.value = false
//         errorSuppliers.value = error
//       }
//       else if (data.data().company.id === SHOP_ID) {
//         const onSuccesUpdateUserData = work => () => {
//           appStore.updateUserData(work)
//           loadingSuppliers.value = false
//           suppliers.value = [
//             ...suppliers.value || [],
//             mapSuppliers(data),
//           ]
//         }

//         const newWork = {
//           ...userData?.work || {},
//           suppliers: [
//             ...userData?.work?.suppliers || [],
//             data.ref,
//           ],
//         }
//         const batch = db.batch()
//         batch.update(
//           userData.reference,
//           {
//             'work.suppliers': FieldValue.arrayUnion(data.ref),
//             ...onUpdate(userData.reference),
//           },
//         ).commit()
//           .then(onSuccesUpdateUserData(newWork))
//           .catch(onError)
//       }
//       else {
//         loadingSuppliers.value = false
//         errorSuppliers.value = 404
//       }
//     }

//     collectionSupplier
//       .where(
//         'code',
//         '==',
//         code,
//       )
//       .where(
//         'isActive',
//         '==',
//         true,
//       )
//       .where(
//         'isBuyOnline',
//         '==',
//         true,
//       )
//       .where(
//         'company',
//         '==',
//         company.reference,
//       )
//       .get()
//       .then(onSuccess)
//       .catch(onError)
//   }

//   const getHomeDeliverySuppliers = () => {
//     const companyStore = useCompanyStore()
//     const { company } = storeToRefs(companyStore)

//     loadingHomeDeliverySuppliers.value = true

//     const onSuccess = ({ docs }) => {
//       loadingHomeDeliverySuppliers.value = false
//       homeDeliverySuppliers.value = docs.map(mapSuppliers)
//     }

//     const onError = () => {
//       loadingHomeDeliverySuppliers.value = false
//     }

//     collectionSupplier
//       .where(
//         'company',
//         '==',
//         company.reference,
//       )
//       .where(
//         'isActive',
//         '==',
//         true,
//       )
//       .where(
//         'isHomeDelivery',
//         '==',
//         true,
//       )
//       .get()
//       .then(onSuccess)
//       .catch(onError)
//   }

//   const removeSupplier = (supplier) => {
//     if (suppliers.value) {
//       const newSuppliers = suppliers.value
//       const byId = item => item.reference.id !== supplier
//       suppliers.value = newSuppliers.filter(byId)
//     }
//   }

//   const selectSupplier = (supplier) => {
//     selectedSupplier.value = supplier
//     selectedSupplierId.value = supplier?.reference?.id
//   }

//   const selectSupplierById = (supplierId) => {
//     const byId = item => item?.reference?.id === supplierId
//     if (suppliers.value) {
//       const selected = suppliers.value.find(byId) || null
//       selectedSupplier.value = selected
//       selectedSupplierId.value = selected?.reference?.id
//     }
//   }

//   const addSupplier = (supplier) => {
//     suppliers.value = [
//       ...suppliers.value || [],
//       supplier,
//     ]
//   }

//   return {
//     loadingSuppliers,
//     suppliers,
//     errorSuppliers,
//     homeDeliverySuppliers,
//     loadingHomeDeliverySuppliers,
//     selectedSupplier,
//     selectedSupplierId,
//     resetState,
//     getUserSuppliers,
//     addSupplierByCode,
//     getHomeDeliverySuppliers,
//     removeSupplier,
//     selectSupplier,
//     selectSupplierById,
//     addSupplier,
//   }
// })

function initialState() {
  return {
    suppliers: null,
    loadingSuppliers: false,
    errorSuppliers: null,

    homeDeliverySuppliers: null,
    loadingHomeDeliverySuppliers: false,

    selectedSupplier: null,
    selectedSupplierId: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {

    resetState({ commit }) {
      commit('RESET_STATE')
    },

    getUserSuppliers({
      commit,
    }, userData) {
      if (!userData || !userData?.work?.suppliers || Array.isArray(userData?.work?.suppliers) && userData?.work?.suppliers.length === 0)
        return

      commit('GET_SUPPLIERS_REQUEST')

      const onSuccess = (data) => {
        commit(
          'GET_SUPPLIERS_SUCCESS',
          data.map(mapSuppliers).filter(supplier => supplier.isActive),
        )
      }

      const onError = (error) => {
        commit(
          'GET_SUPPLIERS_FAILURE',
          error,
        )
      }

      const getSupplier = supplier => supplier.get()

      const suppliersPromises = userData.work.suppliers.map(getSupplier)

      Promise.all(suppliersPromises)
        .then(onSuccess)
        .catch(onError)
    },
    addSupplierByCode({
      commit,
rootState: {
  app: {
    userData,
  },
  company: { company },
},
    }, code) {
      commit('ADD_SUPPLIER_BY_CODE_REQUEST')

      const onError = (error) => {
        commit(
          'ADD_SUPPLIER_BY_CODE_FAILURE',
          error,
        )
      }

      const onSuccess = (result) => {
        const [data] = result.docs
        if (result.empty) {
          commit(
            'ADD_SUPPLIER_BY_CODE_FAILURE',
            404,
          )
        }
        else if (data.data().company.id === SHOP_ID) {
          const onSuccesUpdateUserData = work => () => {
            store.dispatch(
              'app/updateUserData',
              {
                key: 'work',
                value: work,
              },
            )
            commit(
              'ADD_SUPPLIER_BY_CODE_SUCCESS',
              mapSuppliers(data),
            )
          }

          const newWork = {
            ...userData?.work || {},
            suppliers: [
              ...userData?.work?.suppliers || [],
              data.ref,
            ],
          }
          const batch = db.batch()
          batch.update(
            userData.reference,
            {
              'work.suppliers': FieldValue.arrayUnion(data.ref),
              ...onUpdate(userData.reference),
            },
          ).commit()
            .then(onSuccesUpdateUserData(newWork))
            .catch(onError)
        }
        else {
          commit(
            'ADD_SUPPLIER_BY_CODE_FAILURE',
            404,
          )
        }
      }

      collectionSupplier
        .where(
          'code',
          '==',
          code,
        )
        .where(
          'isActive',
          '==',
          true,
        )
        .where(
          'isBuyOnline',
          '==',
          true,
        )
        .where(
          'company',
          '==',
          company.reference,
        )
        .get()
        .then(onSuccess)
        .catch(onError)
    },

    getHomeDeliverySuppliers({
      commit,
rootState: {
  company: { company },
},
    }) {
      commit('GET_HOME_DELIVERY_SUPPLIERS_REQUEST')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_HOME_DELIVERY_SUPPLIERS_SUCCESS',
          docs.map(mapSuppliers),
        )
      }

      const onError = () => {
        commit('GET_HOME_DELIVERY_SUPPLIERS_FAILURE')
      }

      collectionSupplier
        .where(
          'company',
          '==',
          company.reference,
        )
        .where(
          'isActive',
          '==',
          true,
        )
        .where(
          'isHomeDelivery',
          '==',
          true,
        )
        .get()
        .then(onSuccess)
        .catch(onError)
    },

    removeSupplier({ commit }, supplier) {
      commit(
        'REMOVE_SUPPLIER',
        supplier,
      )
    },

    selectSupplier({ commit }, supplier) {
      commit(
        'SELECT_SUPPLIER',
        supplier,
      )
    },
    selectSupplierById({ commit, rootState: { suppliers: { suppliers } } }, supplierId) {
      const byId = item => item?.reference?.id === supplierId
      const selected = suppliers.find(byId) || null
      commit(
        'SELECT_SUPPLIER',
        selected,
      )
    },
    addSupplier({ commit }, supplier) {
      commit(
        'ADD_SUPPLIER',
        supplier,
      )
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_SUPPLIERS_REQUEST(_state) {
      _state.loadingSuppliers = true
    },
    GET_SUPPLIERS_SUCCESS(_state, data) {
      const byCompany = item => item?.company?.id === SHOP_ID

      _state.loadingSuppliers = false
      _state.suppliers = data.filter(byCompany)
    },
    GET_SUPPLIERS_FAILURE(_state) {
      _state.loadingSuppliers = false
    },

    ADD_SUPPLIER_BY_CODE_REQUEST(_state) {
      _state.loadingSuppliers = true
      _state.errorSuppliers = null
    },
    ADD_SUPPLIER_BY_CODE_SUCCESS(_state, data) {
      _state.loadingSuppliers = false
      _state.suppliers = [
        ..._state.suppliers || [],
        data,
      ]
    },
    ADD_SUPPLIER_BY_CODE_FAILURE(_state, error) {
      _state.loadingSuppliers = false
      _state.errorSuppliers = error
    },

    GET_HOME_DELIVERY_SUPPLIERS_REQUEST(_state) {
      _state.loadingHomeDeliverySuppliers = true
    },
    GET_HOME_DELIVERY_SUPPLIERS_SUCCESS(_state, data) {
      _state.loadingHomeDeliverySuppliers = false
      _state.homeDeliverySuppliers = data
    },
    GET_HOME_DELIVERY_SUPPLIERS_FAILURE(_state) {
      _state.loadingHomeDeliverySuppliers = false
    },
    REMOVE_SUPPLIER(_state, supplier) {
      if (_state.suppliers) {
        const newSuppliers = _state.suppliers
        const byId = item => item.reference.id !== supplier
        _state.suppliers = newSuppliers.filter(byId)
      }
    },
    SELECT_SUPPLIER(_state, supplier) {
      _state.selectedSupplier = supplier
      _state.selectedSupplierId = supplier?.reference?.id
    },
    ADD_SUPPLIER(_state, supplier) {
      _state.suppliers = [
        ..._state.suppliers || [],
        supplier,
      ]
    },
  },
}
