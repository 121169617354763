import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import pl from './locales/pl.json'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const messages = {
    pl,
    en,
  }

  return messages
}

export default new VueI18n({
  locale: 'pl',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
