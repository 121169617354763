import { withoutEmptyString } from '~/helpers/text'

export class PlaceModel {
  address: any
  floor: number | null
  name: any
  city: any
  constructor(data) {
    this.address = data.address || null
    this.floor = data.floor
      ? Number(data.floor)
      : null
    this.name = data.name || null
    this.city = data.city || null
  }

  toMap() {
    return {
      name: withoutEmptyString(this.name?.trim()),
      floor: withoutEmptyString(this.floor),
      address: withoutEmptyString(this.address?.trim()),
      city: withoutEmptyString(this.city),
    }
  }
}
