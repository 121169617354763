import 'firebase/compat/functions'
import 'dayjs/locale/pl'
import firebase from 'firebase/compat/app'
import { Timestamp, firebaseApp } from '../db'

function initialState() {
  return {
    serverTime: null,
    fetchedServerTime: null,
    loadingServerTime: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async setServerTime({ commit }) {
      commit('SERVER_TIME_REQUEST')
      let response = null
      let time = null
      try {
        response = await firebase.functions(firebaseApp).httpsCallable('getServerTime')
          .call()

        time = new Timestamp(
          response.data._seconds,
          response.data._nanoseconds,
        ).toDate()
        commit(
          'SET_SERVER_TIME',
          time,
        )
      }
      catch (error) {
        time = new Date()
      }
      commit(
        'SET_SERVER_TIME',
        time,
      )

      return time
    },
  },
  getters: {
    serverTime: ({ serverTime }) => serverTime,
    fetchedServerTime: ({ fetchedServerTime }) => fetchedServerTime,
    loadingServerTime: ({ loadingServerTime }) => loadingServerTime,
  },
  mutations: {
    SET_SERVER_TIME(_state, data) {
      _state.serverTime = data
      _state.fetchedServerTime = new Date()
      _state.loadingServerTime = false
    },
    SERVER_TIME_REQUEST(_state) {
      _state.loadingServerTime = true
    },
  },
}
