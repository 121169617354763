const threeDots = '...'

export function truncate(input, length = 40) {
  return input?.length > length
    ? input.substring(
      0,
      length,
    ) + threeDots
    : input
}

export function phoneNumberFormat(phone) {
  return `${phone.substring(
    0,
    3,
  )} ${phone.substring(
    3,
    6,
  )} ${phone.substring(
    6,
    9,
  )} ${phone.substring(9)}`
}

export function formatPhoneToDatabase(phone) {
  return phone
    .split(' ')
    .join('')
    .split('-')
    .join('')
}

export function withoutEmptyString(value) {
  return value == null || value === ''
    ? null
    : value
}

export function formatPhoneFromDatabase(phone) {
  return phone
    ? `${phone.substring(
      0,
      3,
    )} ${phone.substring(
      3,
      6,
    )}-${phone.substring(
      6,
      9,
    )}-${phone.substring(
      9,
      12,
    )}`
    : '+48'
}
