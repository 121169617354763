import { defineStore } from 'pinia'
import { ref } from 'vue'
import { missingCompaniesCollection } from '../db'

export const useMessagesStore = defineStore('messages', () => {
  const messages = ref(null)
  const messagesLoading = ref(false)
  const messagesSuccess = ref(false)
  const messagesError = ref(null)

  const resetState = () => {
    messages.value = null
    messagesLoading.value = false
    messagesSuccess.value = false
    messagesError.value = null
  }

  const sendContactForm = (data) => {
    messagesLoading.value = true
    messagesSuccess.value = false
    messagesError.value = null

    const onSuccess = () => {
      messagesLoading.value = false
      messagesSuccess.value = true
      messagesError.value = null
    }
    const onError = (error) => {
      messagesLoading.value = false
      messagesSuccess.value = false
      messagesError.value = error
    }
    missingCompaniesCollection.add(data)
      .then(onSuccess)
      .catch(onError)
  }

  return {
    messages,
    messagesLoading,
    messagesSuccess,
    messagesError,
    resetState,
    sendContactForm,
  }
})

function initialState() {
  return {
    messages: null,

    messagesLoading: false,
    messagesSuccess: false,
    messagesError: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    sendContactForm({ commit }, { data }) {
      commit('SEND_MESSAGES_FORM_REQUEST')

      const onSuccess = () => {
        commit('SEND_MESSAGES_FORM_SUCCESS')
      }
      const onError = (error) => {
        commit(
          'SEND_MESSAGES_FORM_FAILURE',
          error,
        )
      }
      missingCompaniesCollection.add(data)
        .then(onSuccess)
        .catch(onError)
    },
  },
  getters: {
    messages: ({ messages }) => messages,
    messagesLoading: ({ messagesLoading }) => messagesLoading,
    messagesSuccess: ({ messagesSuccess }) => messagesSuccess,
    messagesError: ({ messagesError }) => messagesError,
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },
    SEND_MESSAGES_FORM_REQUEST(_state) {
      _state.messagesLoading = true
      _state.messagesSuccess = false
      _state.messagesError = null
    },
    SEND_MESSAGES_FORM_SUCCESS(_state) {
      _state.messagesLoading = false
      _state.messagesSuccess = true
      _state.messagesError = null
    },
    SEND_MESSAGES_FORM_FAILURE(_state, error) {
      _state.messagesLoading = false
      _state.messagesSuccess = false
      _state.messagesError = error
    },
  },
}
