import { collectionNotificationForUsers } from '../db'
import { mapNotifications } from '~/models/NotificationModel'

function initialState() {
  return {
    unsubscribe: null,
    size: null,
    lastVisible: null,
    notifications: null,

    notificationsLoading: false,
    notificationsSuccess: false,
    notificationsError: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({ commit, rootState: { notifications: { unsubscribe } } }) {
      if (unsubscribe) {
        unsubscribe()
        commit(
          'SAVE_UNSUBSCRIBE',
          null,
        )
      }

      commit('RESET_STATE')
    },

    getNotifications({ commit, rootState: { company: { company }, notifications: { unsubscribe } } }, dataSent) {
      commit('GET_NOTIFICATIONS_INIT')

      if (unsubscribe) {
        unsubscribe()
        commit(
          'SAVE_UNSUBSCRIBE',
          null,
        )
      }

      const onSuccess = ({ docs, size }) => {
        if (size !== 0) {
          commit(
            'GET_NOTIFICATIONS_SUCCESS',
            {
              data: docs.map(mapNotifications),
              last: docs[docs.length - 1],
              size,
            },
          )
        }
        else {
          commit('GET_NOTIFICATIONS_LIMIT')
        }
      }

      const onError = (error) => {
        commit(
          'GET_NOTIFICATIONS_FAILURE',
          error,
        )
      }

      if (dataSent.lastVisible) {
        const unsubscribeNew = collectionNotificationForUsers
          .where(
            'user',
            '==',
            dataSent.userData.reference,
          )
          .where(
            'company',
            '==',
            company.reference,
          )
          .orderBy('sendDate', 'desc')
          .startAfter(dataSent.lastVisible)
          .limit(10)
          .onSnapshot(
            onSuccess,
            onError,
          )
        commit(
          'SAVE_UNSUBSCRIBE',
          unsubscribeNew,
        )
      }
      else {
        const unsubscribeNew = collectionNotificationForUsers
          .where(
            'user',
            '==',
            dataSent.userData.reference,
          )
          .where(
            'company',
            '==',
            company.reference,
          )
          .orderBy('sendDate', 'desc')
          .limit(10)
          .onSnapshot(
            onSuccess,
            onError,
          )
        commit(
          'SAVE_UNSUBSCRIBE',
          unsubscribeNew,
        )
      }
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    SAVE_UNSUBSCRIBE(_state, unsubscribe) {
      _state.unsubscribe = unsubscribe
    },

    GET_NOTIFICATIONS_INIT(_state) {
      _state.notificationsLoading = true
      _state.notificationsError = null
    },

    GET_NOTIFICATIONS_SUCCESS(_state, { data, last, size }) {
      _state.size = size
      _state.lastVisible = last
      _state.notificationsLoading = false
      _state.notificationsSuccess = true
      _state.notificationsError = null
      if (_state.notifications) {
        _state.notifications = [
          ..._state.notifications,
          ...data,
        ]
      }
      else {
        _state.notifications = data
      }
    },

    GET_NOTIFICATIONS_LIMIT(_state) {
      _state.notificationsLoading = false
      _state.notificationsSuccess = false
      _state.size = 0
    },

    GET_NOTIFICATIONS_FAILURE(_state, error) {
      _state.notificationsLoading = false
      _state.notificationsSuccess = false
      _state.notificationsError = error
    },
  },
  getters: {
    notifications: ({ notifications }) => notifications,
    notificationsLoading: ({ notificationsLoading }) => notificationsLoading,
    notificationsSuccess: ({ notificationsSuccess }) => notificationsSuccess,
    notificationsError: ({ notificationsError }) => notificationsError,
  },
}
