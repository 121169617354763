import { withoutEmptyString } from '~/helpers/text'

export class HomeModel {
  address: any
  city: any
  postCode: any
  geoPoint: any
  floor: any
  flatNumber: any
  doorCode: any
  description: any
  constructor(data) {
    this.address = data?.address || null
    this.city = data?.city || null
    this.postCode = data?.postCode || null
    this.geoPoint = data?.geoPoint || null
    this.floor = data?.floor || null
    this.flatNumber = data?.flatNumber || null
    this.doorCode = data?.doorCode || null
    this.description = data?.description || null
  }

  toMap() {
    return {
      address: withoutEmptyString(this.address),
      city: withoutEmptyString(this.city),
      postCode: withoutEmptyString(this.postCode),
      geoPoint: withoutEmptyString(this.geoPoint),
      floor: withoutEmptyString(this.floor),
      flatNumber: withoutEmptyString(this.flatNumber),
      doorCode: withoutEmptyString(this.doorCode),
      description: withoutEmptyString(this.description),
    }
  }
}
