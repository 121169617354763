import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pl from 'vuetify/es5/locale/pl'

Vue.use(Vuetify)

export default new Vuetify({
  buttons: {
    capitalize: false,
  },
  lang: {
    locales: { pl },
    current: 'pl',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      light: {
        primary: '#93C714',
        secondary: '#5c6bc0',
        accent: '#3f51b5',
        dark: '#000000',
        background: '#000',
      },
      dark: {
        primary: '#93C714',
        secondary: '#5c6bc0',
        accent: '#3f51b5',
        dark: '#000000',
        background: '#000',
      },
    },
  },
})
