import { FieldValue, auth, db, firebase, usersCollection } from '../db'
import { onUpdate } from '../helpers/actions'
import router from '../router'
import store from '.'
import { NotificationSettings } from '~/models/NotificationSettings'
import { CustomerModel } from '~/models/CustomerModel'

const ROLES = [
  'owner',
  'getdeliver',
  'deliver',
  'manager',
  'moderator',
  'customer',
]

function initialState() {
  return {
    error: null,
    errorLogin: null,
    loading: false,
    user: null,
    userData: null,
    cardData: null,
    cardDataStorage: null,

    loadingNotification: false,
    loadingResetPassword: false,
    loadingUpdatePassword: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {

    resetState({ commit }) {
      commit('RESET_STATE')
    },

    login({ dispatch, commit }, { email, password }) {
      commit('LOGIN_REQUEST')

      const onError = (error) => {
        commit(
          'LOGIN_FAILURE',
          error,
        )
      }

      const onSuccess = ({ user }) => {
        commit('LOGIN_SUCCESS')
        dispatch(
          'fetchUserProfile',
          user,
        )
      }

      auth.signInWithEmailAndPassword(
        email,
        password,
      )
        .then(onSuccess)
        .catch(onError)
    },

    async fetchUserProfile({
      commit,
rootState: {
  app: { userData },
},
    }, user) {
      try {
        const userProfile = await usersCollection.doc(user.uid).get()
        if (!userProfile.exists && !ROLES.includes(user.displayName)) {
          const email = user.email || user.providerData[0].email
          const newUser = new CustomerModel({
            isActive: true,
            email,
            role: 'customer',
            ...onUpdate(userData.reference),
          })

          await user.updateProfile({
            displayName: 'customer',
            email,
          })

          await usersCollection.doc(user.uid).set(newUser.toMap())
        }

        commit(
          'SET_USER',
          {
            user,
            userData: new CustomerModel(
              userProfile.data(),
              userProfile.ref,
            ),
          },
        )
        commit('LOGIN_SUCCESS')
        if (router.currentRoute?.name === 'sign-in' || router.currentRoute?.name === 'login')
          router.replace('/menu').catch(() => { })
      }
      catch (error) {
        commit(
          'LOGIN_FAILURE',
          error,
        )
      }
    },

    async signUp({ dispatch, commit }, { email, password }) {
      try {
        commit('SIGN_UP_REQUEST')

        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          password,
        )

        const newUser = new CustomerModel({
          isActive: true,
          email: user.email,
          role: 'customer',
        })

        await user.updateProfile({ displayName: 'customer' })

        await usersCollection.doc(user.uid).set(newUser.toMap())

        dispatch(
          'fetchUserProfile',
          user,
        )
      }
      catch (error) {
        commit(
          'SIGN_UP_FAILURE',
          error,
        )
      }
    },

    async fetchUserProfile2({ commit }, user) {
      const userProfile = await usersCollection.doc(user.uid).get()

      commit(
        'SET_USER',
        {
          user,
          userData: new CustomerModel(
            userProfile.data(),
            userProfile.ref,
          ),
        },
      )

      router.replace('/menu').catch(() => { })
    },

    resetPassword({ commit }, email) {
      commit('RESET_PASSWORD_REQUEST')

      const onSuccess = () => {
        commit('RESET_PASSWORD_SUCCESS')
      }
      const onError = (error) => {
        commit(
          'RESET_PASSWORD_ERROR',
          error,
        )
      }

      auth.sendPasswordResetEmail(email)
        .then(onSuccess)
        .catch(onError)
    },

    redirectToGoogle({ dispatch }) {
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.addScope('email')

      const onSuccess = async ({ user }) => {
        if (user?.displayName && ROLES.includes(user.displayName)) {
          dispatch(
            'fetchUserProfile',
            user,
          )
        }
        else {
          const email = user.email || user.providerData[0].email
          const newUser = new CustomerModel({
            isActive: true,
            email,
            role: 'customer',
          })

          await user.updateProfile({
            displayName: 'customer',
            email,
          })

          await usersCollection.doc(user.uid).set(newUser.toMap())

          dispatch(
            'fetchUserProfile',
            user,
          )
        }
      }

      auth.signInWithPopup(provider).then(onSuccess)
    },

    redirectToFacebook({ dispatch }) {
      const provider = new firebase.auth.FacebookAuthProvider()
      provider.addScope('email')

      const onSuccess = async ({ user }) => {
        if (user.displayName && ROLES.includes(user.displayName)) {
          dispatch(
            'fetchUserProfile',
            user,
          )
        }
        else {
          const email = user.email || user.providerData[0].email
          const newUser = new CustomerModel({
            isActive: true,
            email,
            role: 'customer',
          })

          await user.updateProfile({
            displayName: 'customer',
            email,
          })

          await usersCollection.doc(user.uid).set(newUser.toMap())

          dispatch(
            'fetchUserProfile',
            user,
          )
        }
      }

      auth.signInWithPopup(provider).then(onSuccess)
    },

    updateUserData({ commit }, { key, value }) {
      commit(
        'UPDATE_USER_DATA',
        {
          key,
          value,
        },
      )
    },

    signOut({ commit }) {
      const onSuccess = () => {
        localStorage.cart = []
        localStorage.selectSupplier = ''
        localStorage.removeItem('cart')
        localStorage.removeItem('selectSupplier')

        store.dispatch('suppliers/resetState')
        store.dispatch('orders/resetState')
        store.dispatch('menu/resetState')
        store.dispatch('app/resetState')

        commit(
          'SET_USER',
          {
            user: null,
            userData: null,
          },
        )

        router.replace('/login').catch(() => { })
      }

      auth.signOut().then(onSuccess)
    },

    removeSupplier({ commit, rootState: { app: { userData } } }, supplier) {
      commit('REMOVE_SUPPLIERS_REQUEST')

      const onSuccess = () => {
        const newUserData = userData.work
        newUserData.suppliers = userData.work.suppliers
          ? userData.work.suppliers.filter(item => item.id !== supplier.reference.id)
          : []
        commit(
          'REMOVE_SUPPLIERS_SUCCESS',
          newUserData,
        )
        store.dispatch(
          'suppliers/removeSupplier',
          supplier.reference.id,
        )
      }

      const onError = () => {
        commit('REMOVE_SUPPLIERS_ERROR')
      }

      const data = {
        'work.suppliers': FieldValue.arrayRemove(supplier.reference),
        ...onUpdate(userData.reference),
      }
      const batch = db.batch()
      batch.update(
        userData.reference,
        data,
      ).commit()
        .then(onSuccess)
        .catch(onError)
    },

    resetPasswordUser({ commit, rootState: { app: { user: { email } } } }) {
      commit('RESET_PASSWORD_REQUEST')

      const onSuccess = () => {
        commit('RESET_PASSWORD_SUCCESS')
      }
      const onError = (error) => {
        commit(
          'RESET_PASSWORD_ERROR',
          error,
        )
      }

      auth.sendPasswordResetEmail(email)
        .then(onSuccess)
        .catch(onError)
    },

    updatePassword({ commit, rootState: { app: { user: { email } } } }, { password, newPassword }) {
      commit('UPDATE_PASSWORD_REQUEST')

      const onSuccessUpdatePassword = () => {
        commit('UPDATE_PASSWORD_SUCCESS')
      }

      const onErrorUpdatePassword = (error) => {
        commit(
          'UPDATE_PASSWORD_ERROR',
          error,
        )
      }

      const onSuccess = () => {
        auth.currentUser.updatePassword(newPassword)
          .then(onSuccessUpdatePassword)
          .catch(onErrorUpdatePassword)
      }

      const onError = (error) => {
        commit(
          'UPDATE_PASSWORD_ERROR',
          error,
        )
      }

      const credentials = firebase.auth.EmailAuthProvider.credential(
        email,
        password,
      )

      auth.currentUser.reauthenticateWithCredential(credentials)
        .then(onSuccess)
        .catch(onError)
    },

    updateUserProfile({ commit, rootState: { app: { userData } } }, data) {
      commit('UPDATE_USER_PROFILE_REQUEST')

      const onSuccess = () => {
        commit(
          'UPDATE_USER_PROFILE_SUCCESS',
          data,
        )
      }

      const onError = (error) => {
        commit(
          'UPDATE_USER_PROFILE_ERROR',
          error,
        )
      }

      userData.reference.update({
        ...data,
        ...onUpdate(userData.reference),
      })
        .then(onSuccess)
        .catch(onError)
    },
    updateUserNotyfication({ commit, rootState: { app: { userData } } }, communicationMethod) {
      commit('UPDATE_USER_PROFILE_NOTIFICATION_REQUEST')

      // eslint-disable-next-line no-restricted-properties, no-proto
      const formatSettings = settings => (settings.__proto__.constructor === NotificationSettings
        ? userData.notificationSettings.toMap()
        : userData.notificationSettings)

      const notificationSettings = userData?.notificationSettings
        ? new NotificationSettings({
          ...formatSettings(userData.notificationSettings),
          communicationMethod,
        })
        : new NotificationSettings({ communicationMethod })

      const onSuccess = () => {
        commit(
          'UPDATE_USER_PROFILE_NOTIFICATION_SUCCESS',
          notificationSettings,
        )
      }

      const onError = (error) => {
        commit(
          'UPDATE_USER_PROFILE_NOTIFICATION_ERROR',
          error,
        )
      }

      const data = {
        notificationSettings: notificationSettings.toMap(),
        ...onUpdate(userData.reference),
      }

      userData.reference.update(data)
        .then(onSuccess)
        .catch(onError)
    },

    // addCard(_, cardData) {
    //   const [
    //     expirationMonth,
    //     expirationYear,
    //   ] = cardData.expiration.split('/')

    //   const data = {
    //     public_api_key: PAYLANE_API_KEY,
    //     card_number: cardData.cardNumber,
    //     expiration_month: expirationMonth,
    //     expiration_year: expirationYear,
    //     name_on_card: cardData.cardName,
    //     card_code: cardData.cvvCode,
    //   }

    //   getAxiosInstancePaylane()
    //     .post(
    //       'generateToken',
    //       JSON.stringify(data),
    //     )
    // },

    // getCreditCard({ commit, rootState: { app: { userData } } }) {
    //   commit('GET_CREDIT_CARD_DATA_REQUEST')

    //   const data = {}

    //   if (userData?.paymentId?.authorizationId)
    //     data.authorizationId = userData.paymentId.authorizationId

    //   if (userData?.paymentId?.saleId)
    //     data.saleId = userData.paymentId.saleId

    //   const onSuccess = (resonse) => {
    //     commit(
    //       'GET_CREDIT_CARD_DATA_SUCCESS',
    //       resonse,
    //     )
    //   }

    //   const onError = (error) => {
    //     commit(
    //       'GET_CREDIT_CARD_DATA_ERROR',
    //       error,
    //     )
    //   }

    //   getAxiosInstance(userData).post(
    //     'getCreditCard',
    //     data,
    //   )
    //     .then(onSuccess)
    //     .catch(onError)
    // },
    // removeCreditCard({ commit, rootState: { app: { userData } } }) {
    //   commit('REMOVE_CREDIT_CARD_REQUEST')

    //   const onSuccess = () => {
    //     commit('REMOVE_CREDIT_CARD_SUCCESS')
    //   }

    //   const onError = (error) => {
    //     commit(
    //       'REMOVE_CREDIT_CARD_FAILURE',
    //       error,
    //     )
    //   }

    //   const batch = db.batch()
    //   batch.update(
    //     userData.reference,
    //     {
    //       paymentId: null,
    //       ...onUpdate(userData.reference),
    //     },
    //   ).commit()
    //     .then(onSuccess)
    //     .catch(onError)
    // },
    addHomeSupplier({ commit, rootState: { app: { userData } } }, { supplier, oldSupplier }) {
      const { work } = userData
      if (oldSupplier) {
        work.suppliers = [
          ...(userData?.work?.suppliers || []).filter(item => item.id !== oldSupplier.reference.id),
          supplier.reference,
        ]
      }
      else {
        work.suppliers = [
          ...userData?.work?.suppliers || [],
          supplier.reference,
        ]
      }
      const onSuccess = () => {
        store.dispatch(
          'suppliers/addSupplier',
          supplier,
        )
        if (oldSupplier) {
          store.dispatch(
            'suppliers/removeSupplier',
            oldSupplier.reference.id,
          )
        }

        commit(
          'ADD_SUPPLIER',
          work,
        )
      }

      const addData = {
        'work.suppliers': FieldValue.arrayUnion(supplier.reference),
        ...onUpdate(userData.reference),
      }
      let removeData = null
      if (oldSupplier) {
        removeData = {
          'work.suppliers': FieldValue.arrayRemove(oldSupplier.reference),
          ...onUpdate(userData.reference),
        }
      }
      const batch = db.batch()
      if (removeData) {
        batch.update(
          userData.reference,
          removeData,
        )
      }
      batch.update(
        userData.reference,
        addData,
      )
      batch.commit()
        .then(onSuccess)
    },
  },
  getters: {
    error: ({ error }) => error,
    loading: ({ loading }) => loading,
    user: ({ user }) => user,
    userData: ({ userData }) => userData,
  },
  mutations: {
    SET_USER(_state, { user, userData }) {
      _state.user = user
      _state.userData = userData
    },
    LOGIN_REQUEST(_state) {
      _state.errorLogin = null
      _state.loading = true
    },
    LOGIN_SUCCESS(_state) {
      _state.loading = false
    },
    LOGIN_FAILURE(_state, error) {
      _state.loading = false
      _state.errorLogin = error
    },

    RESET_PASSWORD_REQUEST(_state) {
      _state.error = null
      _state.loadingResetPassword = true
    },
    RESET_PASSWORD_SUCCESS(_state) {
      _state.loadingResetPassword = false
    },
    RESET_PASSWORD_ERROR(_state, error) {
      _state.loadingResetPassword = false
      _state.error = error
    },

    GET_CREDIT_CARD_DATA_REQUEST(_state) {
      _state.error = null
    },
    GET_CREDIT_CARD_DATA_SUCCESS(_state, cardData) {
      _state.cardData = cardData
    },
    GET_CREDIT_CARD_DATA_ERROR(_state, error) {
      _state.error = error
    },

    UPDATE_PASSWORD_REQUEST(_state) {
      _state.error = null
      _state.loadingUpdatePassword = true
    },
    UPDATE_PASSWORD_SUCCESS(_state) {
      _state.loadingUpdatePassword = false
    },
    UPDATE_PASSWORD_ERROR(_state, error) {
      _state.loadingUpdatePassword = false
      _state.error = error
    },

    UPDATE_USER_PROFILE_NOTIFICATION_REQUEST(_state) {
      _state.error = null
      _state.loadingNotification = true
    },
    UPDATE_USER_PROFILE_NOTIFICATION_SUCCESS(_state, notificationSettings) {
      _state.userData = {
        ..._state.userData,
        notificationSettings,
      }
      _state.loadingNotification = false
    },
    UPDATE_USER_PROFILE_NOTIFICATION_ERROR(_state, error) {
      _state.loadingNotification = false
      _state.error = error
    },

    UPDATE_USER_PROFILE_REQUEST(_state) {
      _state.error = null
      _state.loading = true
    },
    UPDATE_USER_PROFILE_SUCCESS(_state, data) {
      _state.userData = {
        ..._state.userData,
        ...data,
      }
      _state.loading = false
    },
    UPDATE_USER_PROFILE_ERROR(_state, error) {
      _state.loading = false
      _state.error = error
    },

    SIGN_UP_REQUEST(_state) {
      _state.error = null
      _state.loading = true
    },
    SIGN_UP_SUCCESS(_state) {
      _state.loading = false
    },
    SIGN_UP_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },

    REMOVE_SUPPLIERS_REQUEST(_state) {
      _state.error = null
      _state.loading = true
    },
    REMOVE_SUPPLIERS_SUCCESS(_state, data) {
      _state.loading = false
      _state.userData.work = data
    },
    REMOVE_SUPPLIERS_ERROR(_state) {
      _state.loading = false
    },

    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    UPDATE_USER_DATA(_state, { key, value }) {
      if (!key.includes('.')) {
        const copy = _state.userData
        copy[key] = value
        _state.userData = copy
      }
      else {
        const copy = _state.userData
        const [
          key1,
          key2,
        ] = key.split('.')
        copy[key1][key2] = value
        _state.userData = copy
      }
    },

    ADD_SUPPLIER(_state, work) {
      const { userData } = _state
      userData.work = work
      _state.userData = userData
    },

  },
}
