export class SubsidyModel {
  isUseSubsidyIdentifier: any
  subsidyPrice: any
  isCompanyPayOverflow: any
  canOrderOverflow: any
  paymentPercentageSeparation: any
  budgetPeriod: any
  constructor(data) {
    this.isUseSubsidyIdentifier = data.isUseSubsidyIdentifier || false
    this.subsidyPrice = data.subsidyPrice || null
    this.isCompanyPayOverflow = data.isCompanyPayOverflow || false
    this.canOrderOverflow = data.canOrderOverflow || false
    this.paymentPercentageSeparation = data.paymentPercentageSeparation || 0
    this.budgetPeriod = data.budgetPeriod || null
  }

  toMap() {
    return {
      isUseSubsidyIdentifier: this.isUseSubsidyIdentifier,
      subsidyPrice: this.subsidyPrice,
      isCompanyPayOverflow: this.isCompanyPayOverflow,
      canOrderOverflow: this.canOrderOverflow,
      paymentPercentageSeparation: this.paymentPercentageSeparation,
      budgetPeriod: this.budgetPeriod,
    }
  }
}
