<script lang="ts">
import { mdiAccount, mdiBell, mdiCart, mdiChevronDown, mdiClose } from '@mdi/js'
import { mapState } from 'vuex'

export default {
  name: 'App',

  data() {
    return {
      mdiChevronDown,
      mdiClose,
      mdiAccount,
      mdiCart,
      mdiBell,

      visible: false,
    }
  },

  computed: {
    ...mapState(
      'app',
      [
        'user',
        'userData',
      ],
    ),

    ...mapState(
      'orders',
      ['orders'],
    ),

    ...mapState(
      'company',
      ['company'],
    ),

    ...mapState(
      'notifications',
      ['notifications'],
    ),

    ...mapState(
      'drawer',
      ['showCartStore'],
    ),

    theme() {
      return this.$vuetify.theme.dark
        ? 'dark'
        : 'light'
    },
  },

  watch: {
    userData(userData) {
      if (this.company && userData && this.orders === null) {
        this.$store.dispatch(
          'orders/getOrders',
          userData,
        )
      }

      if (this.company && userData && this.notifications === null) {
        this.$store.dispatch(
          'notifications/getNotifications',
          {
            userData: this.userData,
            lastVisible: null,
          },
        )
      }
    },

    company(company) {
      if (company && this.userData && this.orders === null) {
        this.$store.dispatch(
          'orders/getOrders',
          this.userData,
        )
      }
    },

    orders(ordersNew, ordersOld) {
      if (this.userData && this.orders !== null && ordersNew !== ordersOld) {
        this.$store.dispatch(
          'orders/getOrdersDelivers',
          this.userData,
        )
      }
    },
  },

  mounted() {
    setTimeout(
      () => this.scrollFix(this.$route.hash),
      1,
    )
    if (this.company && this.userData && this.orders === null) {
      this.$store.dispatch(
        'orders/getOrders',
        this.userData,
      )
    }

    if (this.company && this.userData && this.notifications === null) {
      this.$store.dispatch(
        'notifications/getNotifications',
        {
          userData: this.userData,
          lastVisible: null,
        },
      )
    }

    if (!this.company)
      this.$store.dispatch('company/getCompany')

    window.addEventListener(
      'resize',
      this.handleResize,
    )
  },

  beforeUnmount() {
    window.removeEventListener(
      'resize',
      this.handleResize,
    )
  },

  methods: {
    toggleLang() {
      this.$i18n.locale = this.$i18n.locale === 'pl'
        ? 'en'
        : 'pl'
    },

    toggleDrawer() {
      this.visible = !this.visible
    },

    signOut() {
      this.$store.dispatch('app/signOut')
    },

    scrollFix(hashbang) {
      location.hash = hashbang
    },

    goToHashPage(pageName) {
      if (this.$router.currentRoute.path === '/') {
        this.scrollFix(`#${pageName}`)
      }
      else {
        this.$router.replace('/')

        const setTimeOutFunc = () => {
          if (pageName === 'start-page') {
            window.scrollTo(
              0,
              0,
            )
          }
          else {
            this.scrollFix(`#${pageName}`)
          }
        }

        setTimeout(
          setTimeOutFunc,
          300,
        )
      }
      this.visible = false
    },

    goToStartPage() {
      this.goToHashPage('start-page')
    },

    goToAboutUs() {
      this.goToHashPage('about-us')
    },

    goToContact() {
      this.goToHashPage('contact')
    },

    goToInviteShop() {
      this.goToHashPage('invite-shop')
    },

    goToNotificationPage() {
      if (this.$router.currentRoute.path !== '/notifications')
        this.$router.replace('notifications')
    },

    toggleCart() {
      if (this.$router.currentRoute.name !== 'menu')
        this.$router.replace({ name: 'menu' }).catch(() => { })

      if (this.showCartStore)
        this.$store.dispatch('drawer/hideCart')
      else
        this.$store.dispatch('drawer/showCart')
    },
  },
}
</script>

<template>
  <v-app
    style="overflow-y: hidden"
  >
    <v-app-bar
      style="z-index: 99;"
      app
      color="rgba(0, 0, 0, 0.9)"
    >
      <v-navigation-drawer
        v-model="visible"
        class="d-lg-none d-md-none d-xl-none"
        app
        width="100%"
        color="black"
      >
        <v-list>
          <v-list-item
            @click="toggleDrawer"
          >
            <v-icon>{{ mdiClose }}</v-icon>
          </v-list-item>

          <v-list-item
            @click="goToStartPage"
          >
            <span
              class="text-none"
            >{{ $t("App.start") }}</span>
          </v-list-item>

          <v-list-item
            @click="goToAboutUs"
          >
            <span
              class="text-none"
            >{{ $t("App.aboutUs") }}</span>
          </v-list-item>

          <v-list-group
            :value="false"
          >
            <template
              #activator
            >
              <v-list-item-title>{{ $t("App.contact") }}</v-list-item-title>
            </template>

            <v-list-item
              @click="goToInviteShop"
            >
              <span
                class="text-none"
              >{{ $t("App.inviteShop") }}</span>
            </v-list-item>

            <v-list-item
              @click="goToContact"
            >
              <span
                class="text-none"
              >{{ $t("App.contactToUs") }}</span>
            </v-list-item>
          </v-list-group>

          <v-list-item
            to="/menu"
          >
            <span
              class="text-none"
            >{{ $t("App.shop") }}</span>
          </v-list-item>

          <v-list-item
            v-if="$i18n.locale === 'pl'"
            @click="toggleLang"
          >
            <span
              class="text-none"
            >{{ $t("English") }}</span>
          </v-list-item>

          <v-list-item
            v-else
            @click="toggleLang"
          >
            <span
              class="text-none"
            >{{ $t("Polish") }}</span>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar-nav-icon
        class="d-lg-none d-md-none d-xl-none"
        @click="toggleDrawer"
      />

      <v-spacer
        v-if="user"
        class="d-lg-none d-md-none d-xl-none"
      />

      <v-btn
        v-if="user"
        class="d-lg-none d-md-none d-xl-none"
        offset-y
        icon
        @click="toggleCart"
      >
        <v-icon>{{ mdiCart }}</v-icon>
      </v-btn>

      <v-spacer
        class="d-lg-none d-md-none d-xl-none"
      />

      <v-btn
        v-if="user"
        class="d-lg-none d-md-none d-xl-none"
        icon
        depressed
        @click="goToNotificationPage"
      >
        <v-badge
          v-if="notifications && notifications.length"
          overlap
          :content="notifications.length"
        >
          <v-icon>{{ mdiBell }}</v-icon>
        </v-badge>

        <v-icon
          v-else
        >
          {{ mdiBell }}
        </v-icon>
      </v-btn>

      <v-menu
        v-if="user"
        offset-y
      >
        <template
          #activator="{on, attrs}"
        >
          <v-btn
            class="d-lg-none d-md-none d-xl-none"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ mdiAccount }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="user && orders && orders.length > 0"
            to="/orders"
          >
            <span
              class="text-none"
            >{{ $t("App.orders") }}</span>
          </v-list-item>

          <v-list-item
            to="/profile"
          >
            <span
              class="text-none"
            >{{ $t("App.settings") }}</span>
          </v-list-item>

          <v-list-item
            @click="signOut"
          >
            <span
              class="text-none"
            >{{ $t("App.logOut") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        v-else
        offset-y
      >
        <template
          #activator="{on, attrs}"
        >
          <v-btn
            class="d-lg-none d-md-none d-xl-none"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ mdiAccount }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            to="/login"
          >
            <span
              class="text-none"
            >{{ $t("App.login") }}</span>
          </v-list-item>

          <v-list-item
            to="/sign-in"
          >
            <span
              class="text-none"
            >{{ $t("App.signUp") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-row
        no-gutters
        align="center"
        class="d-lg-flex d-md-flex d-none d-xl-flex"
      >
        <v-col>
          <v-btn
            v-if="$i18n.locale === 'pl'"
            outlined
            icon
            small
            class="mr-2"
            @click="toggleLang"
          >
            {{ $t("en") }}
          </v-btn>

          <v-btn
            v-else
            outlined
            small
            icon
            class="mr-2"
            @click="toggleLang"
          >
            {{ $t("pl") }}
          </v-btn>
        </v-col>

        <v-spacer />

        <v-col
          class="text-center"
          style="min-width: 390px;"
        >
          <v-btn
            text
            @click="goToStartPage"
          >
            <span
              class="text-none"
            >{{ $t("App.start") }}</span>
          </v-btn>

          <v-btn
            text
            @click="goToAboutUs"
          >
            <span
              class="text-none"
            >{{ $t("App.aboutUs") }}</span>
          </v-btn>

          <v-menu
            offset-y
          >
            <template
              #activator="{on, attrs}"
            >
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
              >
                <span
                  class="text-none"
                >{{ $t("App.contactExt") }}</span>

                <v-icon
                  right
                >
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="goToInviteShop"
              >
                <span
                  class="text-none"
                >{{ $t("App.inviteShop") }}</span>
              </v-list-item>

              <v-list-item
                @click="goToContact"
              >
                <span
                  class="text-none"
                >{{ $t("App.contactToUs") }}</span>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            to="/menu"
            text
          >
            <span
              class="text-none"
            >{{ $t("App.shop") }}</span>
          </v-btn>
        </v-col>

        <v-spacer />

        <v-col
          v-if="user"
          class="text-right"
          style="min-width: 350px;"
        >
          <v-btn
            v-if="orders && orders.length > 0"
            text
            to="/orders"
            color="primary"
          >
            <span
              class="text-none"
            >{{ $t("App.orders") }}</span>

            <v-img
              class="ml-2"
              src="/images/menu/cart.png"
              contain
              width="20"
            />
          </v-btn>

          <v-btn
            icon
            depressed
            @click="goToNotificationPage"
          >
            <v-badge
              v-if="notifications && notifications.length"
              overlap
              :content="notifications.length"
            >
              <v-icon>{{ mdiBell }}</v-icon>
            </v-badge>

            <v-icon
              v-else
            >
              {{ mdiBell }}
            </v-icon>
          </v-btn>

          <v-menu
            offset-y
          >
            <template
              #activator="{on, attrs}"
            >
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
              >
                <span
                  class="text-none"
                >{{ $t("App.profile") }}</span>

                <v-icon
                  right
                >
                  {{ mdiChevronDown }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                to="/profile"
              >
                <span
                  class="text-none"
                >{{ $t("App.settings") }}</span>
              </v-list-item>

              <v-list-item
                @click="signOut"
              >
                <span
                  class="text-none"
                >{{ $t("App.logOut") }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <v-col
          v-else
          class="text-right"
          style="min-width: 245px;"
        >
          <v-btn
            text
            to="/login"
          >
            <span
              class="text-none"
            >{{ $t("App.login") }}</span>
          </v-btn>

          <v-btn
            text
            to="/sign-in"
          >
            <span
              class="text-none"
            >{{ $t("App.signUp") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main
      class="mb-0 pb-0"
      style="padding: 0px"
    >
      <router-view />
    </v-main>

    <TheCookies />
  </v-app>
</template>

<style>
html {
  overflow-y: auto !important;
  background: black !important;
}

body {
  overflow-y: auto;
  height: 100vh;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: auto !important;
  background: black !important;
}

::-webkit-scrollbar {
  background: #202020;
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

::-webkit-scrollbar-track-piece {
  background: #202020;
}

::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: white;
}
</style>
