export class UpdateApplicationModel {
  isPayOnline: any
  isBlikPayment: any
  reference: any
  constructor(data, reference) {
    this.isPayOnline = data.isPayOnline || false
    this.isBlikPayment = data.isBlikPayment || false

    this.reference = reference || null
  }

  toMap() {
    return {
      isPayOnline: this.isPayOnline,
      isBlikPayment: this.isBlikPayment,
    }
  }
}

export function mapUpdateApplication(data) {
  return new UpdateApplicationModel(
    data.data(),
    data.ref,
  )
}
