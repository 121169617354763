import { Timestamp } from '~/db'

export class NotificationModel {
  company: any
  sendDate: any
  user: any
  raportDetails: any
  reference: any
  constructor(data, reference) {
    this.company = data.company || null
    this.sendDate = new Timestamp(data.sendDate.seconds, data.sendDate.nanoseconds)
    this.user = data.user || null
    this.raportDetails = data.raportDetails || null

    this.reference = reference
  }

  toMap() {
    return {
      company: this.company,
      sendDate: this.sendDate,
      user: this.user,
      raportDetails: this.raportDetails,
    }
  }
}

export function mapNotifications(data) {
  return new NotificationModel(
    data.data(),
    data.ref,
  )
}
