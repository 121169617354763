import { HomeModel } from './HomeModel'
import { NotificationSettings } from './NotificationSettings'
import { WorkModel } from './WorkModel'
import { withoutEmptyString } from '~/helpers/text'

export class CustomerModel {
  favouriteDishes: any
  notificationToken: any
  notificationPlayerId: any
  notificationSettings: NotificationSettings | null
  appVersion: any
  preferences: any
  displayName: any
  email: any
  phone: any
  ordersAvailable: any
  dislikeDishes: any
  languageCode: any
  work: WorkModel
  role: any
  isActive: any
  home: HomeModel
  paymentId: any
  invoice: any
  subsidyBudget: any
  createdByUser: any
  lastUpdateByUser: any
  lastUpdateTime: any
  reference: any
  constructor(data, reference) {
    this.favouriteDishes = data?.favouriteDishes || []
    this.notificationToken = data?.notificationToken || null
    this.notificationPlayerId = data?.notificationPlayerId || null
    this.notificationSettings = data?.notificationSettings
      ? new NotificationSettings(data.notificationSettings)
      : null
    this.appVersion = data?.appVersion || null
    this.preferences = data?.preferences || []
    this.displayName = data?.displayName || null
    this.email = data?.email
    this.phone = data?.phone || null
    this.ordersAvailable = data?.ordersAvailable || true
    this.dislikeDishes = data?.dislikeDishes || []
    this.languageCode = data?.languageCode || 'pl'
    this.work = new WorkModel(data?.work)
    this.role = data?.role || 'customer'
    this.isActive = data?.isActive || true
    this.home = new HomeModel(data?.home)
    this.paymentId = data?.paymentId || null
    this.invoice = data?.invoice || null
    this.subsidyBudget = data?.subsidyBudget

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null

    this.reference = reference || null
  }

  toMap() {
    return {
      favouriteDishes: this.favouriteDishes || [],
      notificationToken: this.notificationToken || null,
      notificationPlayerId: this.notificationPlayerId || null,
      notificationSettings: this?.notificationSettings
        ? this.notificationSettings?.toMap()
        : null,
      appVersion: this.appVersion || null,
      preferences: this.preferences || [],
      displayName: withoutEmptyString(this.displayName),
      email: this.email,
      phone: withoutEmptyString(this.phone),
      ordersAvailable: this.ordersAvailable || true,
      dislikeDishes: this.dislikeDishes || [],
      languageCode: this.languageCode || 'pl',
      work: this.work?.toMap(),
      role: this.role || 'customer',
      isActive: this.isActive || true,
      home: this.home?.toMap(),
      paymentId: this.paymentId || null,
      invoice: this.invoice || null,
      subsidyBudget: this.subsidyBudget || null,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,

    }
  }
}
