<script setup>
import { onMounted, ref } from 'vue'

const showCookies = ref(false)
const showSettings = ref(false)

function acceptCookies() {
  localStorage.cookies = JSON.stringify(true)
  showCookies.value = false
}

function rejectSettings() {
  localStorage.cookies = JSON.stringify(false)
  showCookies.value = false
}

onMounted(() => {
  showCookies.value = !localStorage?.cookies
})
</script>

<template>
  <v-snackbar
    v-model="showCookies"
    :timeout="-1"
    style="opacity: 0.9;"
  >
    <div v-if="!showSettings">
      <v-row class="justify-center">
        <h3 class="ma-4">
          {{ $t("Cookies.title") }}
        </h3>
      </v-row>

      <v-row dense>
        <span>
          {{ $t("Cookies.text") }}
        </span>
      </v-row>

      <br>

      <br>

      <v-row class="justify-center pb-4">
        <v-btn
          class="mx-1"
          color="primary"
          @click="acceptCookies"
        >
          {{ $t("Cookies.accept") }}
        </v-btn>

        <v-btn
          class="mx-1"
          variant="text"
          @click="rejectSettings"
        >
          {{ $t("Cookies.reject") }}
        </v-btn>
      </v-row>
    </div>
  </v-snackbar>
</template>
