import i18n from '~/i18n'

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phonePattern = /^\+48[ ]\d{3}[-]\d{3}[-]\d{3}$/
const postCodePattern = /^[0-9]{2}-[0-9]{3}$/
const digitsPattern = /^[0-9]+$/
const supplierCodePattern = /^[0-9]{3}-[0-9]{3}$/
const realDigitsPattern = /^([0-9]+(\.[0-9]+)?)$/
const nipPattern = /^\d{10}$/

export const phoneMask = '+## ###-###-###'
export const postCodeMask = '##-###'

export function requiredRule(
  value,
  textError = i18n.t('Rules.requiredField'),
) {
  return Boolean(value) || textError
}

export function requiredMultiSelectRule(
  value,
  textError = i18n.t('Rules.requiredField'),
) {
  return Boolean(value?.length) || textError
}

export function requiredSelectRule(
  value,
  textError = i18n.t('Rules.requiredField'),
) {
  return value === 0 || Boolean(value) || textError
}

export function requiredMultiItemsRule(
  value,
  textError = i18n.t('Rules.requiredField'),
) {
  return value && value.length > 0 || textError
}

export function emailRule(
  value,
  textError = i18n.t('Rules.wrongEmailFormat'),
) {
  return emailPattern.test(value) || textError
}

export function phoneRule(
  value,
  textError = i18n.t('Rules.wrongPhoneFormat'),
) {
  return phonePattern.test(value) || textError
}

export const phoneNotRequiredRule = value => value === '+48 ' || value === '+48' || !value || phoneRule(value)

export function phoneRuleNotRequired(
  value,
  textError = i18n.t('Rules.wrongPhoneFormat'),
) {
  return phonePattern.test(value) || value === null || value === '+48 ' || textError
}

export function nipRule(
  value,
  textError = i18n.t('Rules.wrongNIP'),
) {
  return nipPattern.test(value) || textError
}

export function postCodeRule(
  value,
  textError = i18n.t('Rules.wrongPostCode'),
) {
  return postCodePattern.test(value) || textError
}

export function supplierCode(
  value,
  textError = i18n.t('Rules.wrongSupplierCode'),
) {
  return supplierCodePattern.test(value) && value.length === 7 || textError
}

export const digitsRule = (value, textError = i18n.t('Rules.onlyNumbers')) => digitsPattern.test(value) || textError

export function realDigitsRule(
  value,
  textError = i18n.t('Rules.wrongDigitNumber'),
) {
  return realDigitsPattern.test(value) || textError
}

export function lengthRule(value, maxCounter, textError = i18n.t('Rules.lengthRule')) {
  return Boolean((value || '')?.length <= maxCounter) || textError
}

export const textFiledRequiredRules = [requiredRule]
export const selectRequiredRules = [requiredSelectRule]
