import { AddressModel } from './AddressModel'
import { AgreementModel } from './AgreementModel'

export class CompanyModel {
  nip: any
  address: AddressModel
  cities: any
  email: any
  isActive: any
  logoPath: any
  logo: any
  name: any
  phone: any
  useSms: any
  workingDays: any
  trackLocationWhenSendNotification: any
  tomorrowOrderTime: any
  subMerchantIdsPrzelewy: any
  subMerchantIdPrzelewy: any
  subMerchantFee: any
  joiningTime: any
  acceptOrdersTime: any
  workingHours: any
  createdByUser: any
  lastUpdateByUser: any
  lastUpdateTime: any
  agreement: AgreementModel | null
  reference: any
  constructor(data, reference) {
    this.nip = data.nip || null
    this.address = new AddressModel(data.address)
    this.cities = data.cities || null
    this.email = data.email || null
    this.isActive = data.isActive || false
    this.logoPath = data.logoPath || null
    this.logo = data.logo || null
    this.name = data.name || null
    this.phone = data.phone || null
    this.useSms = data.useSms || false
    this.workingDays = data.workingDays || null
    this.trackLocationWhenSendNotification
      = data.trackLocationWhenSendNotification || false
    this.tomorrowOrderTime = data.tomorrowOrderTime || null
    this.subMerchantIdsPrzelewy = data.subMerchantIdsPrzelewy || {}
    this.subMerchantIdPrzelewy = data.subMerchantIdPrzelewy || null
    this.subMerchantFee = data.subMerchantFee || null
    this.joiningTime = data.joiningTime || null
    this.acceptOrdersTime = data.acceptOrdersTime || null
    this.workingHours = data.workingHours || null
    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null

    this.agreement = data.agreement
      ? new AgreementModel(data.agreement)
      : null

    this.reference = reference
  }

  toMap() {
    return {
      nip: this.nip?.trim() || null,
      address: this.address.toMap(),
      cities: this.cities,
      email: this.email,
      isActive: this.isActive,
      logo: this.logo,
      logoPath: this.logoPath,
      name: this.name?.trim() || null,
      phone: this.phone,
      tomorrowOrderTime: this.tomorrowOrderTime,
      useSms: this.useSms,
      workingDays: this.workingDays,
      subMerchantIdsPrzelewy: this.subMerchantIdsPrzelewy,
      subMerchantIdPrzelewy: this.subMerchantIdPrzelewy,
      trackLocationWhenSendNotification: this.trackLocationWhenSendNotification,
      agreement: this.agreement
        ? this.agreement.toMap()
        : {},
      joiningTime: this.joiningTime,
      acceptOrdersTime: this.acceptOrdersTime || null,
      workingHours: this.workingHours || null,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,

    }
  }
}

export function mapCompany(company) {
  return new CompanyModel(
    company.data(),
    company.ref,
  )
}
