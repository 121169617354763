<script setup lang="ts">
import { mdiApple, mdiChevronRight, mdiGooglePlay } from '@mdi/js'
import Vue, { computed, getCurrentInstance, onMounted, ref } from 'vue'
import checkView from 'vue-check-view'

Vue.use(checkView)

const publicPath = import.meta.env.BASE_URL
const selectedMovieOffice = ref(true)

const { $root } = getCurrentInstance().proxy
const { $vuetify } = $root

const isPhone = computed(() => {
  const { name } = $vuetify.breakpoint
  const result = name === 'xs' || name === 'sm' || name === 'md'

  return result
})

const activeOfficeButton = computed(() => {
  return selectedMovieOffice.value
    ? ''
    : 'color: white;'
})

const activeHomeButton = computed(() => {
  return selectedMovieOffice.value
    ? 'color: white;'
    : ''
})

onMounted(() => {
  const playPromise = document.getElementById('tutorial1')?.play()
  const playPromise2 = document.getElementById('tutorial3')?.play()

  if (playPromise)
    playPromise.then(() => { }).catch(() => { })

  if (playPromise2)
    playPromise2.then(() => { }).catch(() => { })
})

function selectOfficeMovie() {
  const playPromise = document.getElementById('tutorial1')?.play()

  if (playPromise)
    playPromise.then(() => { }).catch(() => { })

  selectedMovieOffice.value = true
}

function selectHomeMovie() {
  const playPromise = document.getElementById('tutorial2')?.play()
  if (playPromise)
    playPromise.then(() => { }).catch(() => { })

  selectedMovieOffice.value = false
}

function videoViewHandler({ type }) {
  if (type === 'enter' && document.getElementById('tutorial1')?.paused) {
    if (!(document.documentElement.clientWidth < 600)) {
      const playPromise = document.getElementById('tutorial1')?.play()
      const playPromise2 = document.getElementById('tutorial3')?.play()

      if (playPromise)
        playPromise.then(() => { }).catch(() => { })

      if (playPromise2)
        playPromise2.then(() => { }).catch(() => { })
    }
  }
  else if (type === 'exit' && !document.getElementById('tutorial1')?.paused) {
    if ((document.documentElement.clientWidth < 600))

      document.getElementById('tutorial1')?.pause()
  }
}
</script>

<template>
  <v-container
    class="px-4 py-8"
  >
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-row
        align="center"
        justify="space-between"
        class="fill-height fluid text-left"
      >
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="8"
          class="pa-0"
        >
          <v-row
            justify="center"
            no-gutters
          >
            <v-col
              class="mx-3 my-0 pa-0"
            >
              <h1
                class="mb-2"
              >
                {{ $t("Application.title") }}
              </h1>

              <p>{{ $t("Application.info1") }}</p>

              <p>{{ $t("Application.info2") }}</p>

              <p>{{ $t("Application.info3") }}</p>

              <p
                class="d-none d-sm-flex"
              >
                {{ $t("Application.info4") }}
              </p>

              <v-row
                class="d-none d-sm-flex mb-2 mt-7 mx-0"
              >
                <v-btn
                  class="mr-2"
                  href="https://apps.apple.com/us/app/kanapkaman/id1486009538?ign-mpt=uo%3D4"
                  outlined
                  large
                  color="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <v-icon
                    left
                  >
                    {{ mdiApple }}
                  </v-icon>

                  <span
                    large
                    class="font-weight-bold text-none"
                  >{{ $t("Application.appStore") }}</span>

                  <v-icon
                    right
                  >
                    {{ mdiChevronRight }}
                  </v-icon>
                </v-btn>

                <v-btn
                  class="mx-2"
                  href="https://play.google.com/store/apps/details?id=com.kanapkaman.kanapkaman"
                  outlined
                  large
                  color="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <v-icon
                    left
                  >
                    {{ mdiGooglePlay }}
                  </v-icon>

                  <span
                    class="font-weight-bold text-none"
                  >{{ $t("Application.googlePlay") }}</span>

                  <v-icon
                    right
                  >
                    {{ mdiChevronRight }}
                  </v-icon>
                </v-btn>
              </v-row>

              <br>

              <p
                class="d-none d-sm-flex"
              >
                {{ $t("Application.fcbInfo") }}
              </p>

              <div
                class="d-none d-sm-flex mt-6"
              >
                <v-btn
                  href="https://www.facebook.com/SandwiczSzop"
                  outlined
                  target="_blank"
                  rel="noopener noreferrer"
                  large
                  color="primary"
                >
                  <span
                    class="font-weight-bold text-none"
                  >{{ $t("Application.fcb") }}</span>

                  <v-icon
                    right
                  >
                    {{ mdiChevronRight }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
        >
          <v-row
            v-if="isPhone"
            justify="center"
            align="center"
          >
            <v-img
              src="/images/iPhone.png"
              contain
              width="250px"
              height="500px"
              class="align-center justify-center text-center"
            >
              <video
                v-show="selectedMovieOffice"
                id="tutorial3"
                v-view="videoViewHandler"
                style="margin: auto 0px; width:213px;"
                playsinline
                muted
                width="213px"
                autobuffer
                loop
              >
                <source
                  :src="`${publicPath}movies/mobile.mov`"
                >
              </video>
            </v-img>
          </v-row>

          <v-row
            v-else
            class="ml-4"
            style="width: 310px"
            justify="center"
            align="center"
          >
            <v-img
              src="/images/iPhone.png"
              contain
              width="310px"
              height="580px"
              class="align-center justify-center text-center"
              style="justify: center; align: center; z-index: 1;"
            >
              <video
                v-show="selectedMovieOffice"
                id="tutorial1"
                v-view="videoViewHandler"
                style="margin: auto 0px; width: 250px;"
                playsinline
                muted
                preload
                width="250px"
                autobuffer
                loop
              >
                <source
                  :src="`${publicPath}movies/pc_office.mov`"
                >
              </video>

              <video
                v-show="!selectedMovieOffice"
                id="tutorial2"
                style="margin: auto 0px; width: 250px;"
                playsinline
                muted
                width="250px"
                autobuffer
                loop
              >
                <source
                  :src="`${publicPath}movies/pc_home.mov`"
                >
              </video>
            </v-img>

            <div
              style="position: absolute; padding: 0px 420px 0px 0px; display: grid;"
            >
              <v-btn
                text
                large
                color="primary"
                height="124px"
                width="150px"
                style="background: #181818; margin-bottom: 20px"
                @click="selectOfficeMovie"
              >
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <span
                    :style="activeOfficeButton"
                    class="font-weight-bold text-none"
                  >{{ $t("Application.button1a") }}</span>

                  <br>

                  <span
                    :style="activeOfficeButton"
                    class="font-weight-bold text-none"
                  >{{ $t("Application.button1b") }}</span>

                  <br>

                  <span
                    :style="activeOfficeButton"
                    class="font-weight-bold text-none"
                  >{{ $t("Application.button1c") }}</span>
                </v-col>
              </v-btn>

              <v-btn
                text
                large
                color="primary"
                height="124px"
                width="150px"
                style="background: #181818;  margin-top: 20px"
                @click="selectHomeMovie"
              >
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <span
                    :style="activeHomeButton"
                    class="font-weight-bold text-none"
                  >{{ $t("Application.button2a") }}</span>

                  <br>

                  <span
                    :style="activeHomeButton"
                    class="font-weight-bold text-none"
                  >{{ $t("Application.button2b") }}</span>

                  <br>

                  <span
                    :style="activeHomeButton"
                    class="font-weight-bold text-none"
                  >{{ $t("Application.button2c") }}</span>
                </v-col>
              </v-btn>
            </div>
          </v-row>
        </v-col>

        <v-col
          class="d-sm-none"
        >
          <p>{{ $t("Application.info4") }}</p>

          <v-btn
            href="https://apps.apple.com/us/app/kanapkaman/id1486009538?ign-mpt=uo%3D4"
            outlined
            large
            block
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon
              left
            >
              {{ mdiApple }}
            </v-icon>

            <span
              large
              class="font-weight-bold text-none"
            >{{ $t("Application.appStore") }}</span>

            <v-icon
              right
            >
              {{ mdiChevronRight }}
            </v-icon>
          </v-btn>

          <br>

          <v-btn
            block
            href="https://play.google.com/store/apps/details?id=com.kanapkaman.kanapkaman"
            outlined
            large
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon
              left
            >
              {{ mdiGooglePlay }}
            </v-icon>

            <span
              class="font-weight-bold text-none"
            >{{ $t("Application.googlePlay") }}</span>

            <v-icon
              right
            >
              {{ mdiChevronRight }}
            </v-icon>
          </v-btn>

          <br>

          <p>{{ $t("Application.fcbInfo") }}</p>

          <v-btn
            href="https://www.facebook.com/SandwiczSzop"
            outlined
            large
            block
            color="primary"
          >
            <span
              class="font-weight-bold text-none"
            >{{ $t("Application.fcb") }}</span>

            <v-icon
              right
            >
              {{ mdiChevronRight }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>
