import { FieldValue } from '../db'

export function onCreate(userDataReference) {
  return {
    createdByUser: userDataReference,
    lastUpdateByUser: userDataReference,
    lastUpdateTime: FieldValue.serverTimestamp(),
  }
}

export function onUpdate(userDataReference) {
  return {
    lastUpdateByUser: userDataReference,
    lastUpdateTime: FieldValue.serverTimestamp(),
  }
}
